<template>
    <v-container fluid class="d-flex justify-space-between pa-0">
        <v-expand-x-transition>
            <filter-panel
                v-if="expand"
                @handle-event="applyFilter"></filter-panel>
        </v-expand-x-transition>

        <v-card
            elevation="0"
            :width="expand ? '80%' : '100%'"
            height="790px"
            class="overflow-y-auto grey lighten-3">
            <v-card-text>
                <v-container>
                    <v-container class="d-flex"
                        ><v-btn
                            class="mt-2"
                            color="primary"
                            depressed
                            rounded
                            @click="expand = !expand"
                            ><i class="fa-solid fa-bars"></i
                        ></v-btn>
                        <v-text-field
                            dense
                            class="ma-2"
                            rounded
                            outlined
                            v-model="search"
                            label="Title Filter"
                            clearable></v-text-field
                    ></v-container>

                    <v-container
                        class="d-flex justify-space-between white rounded-xl mb-2"
                        v-for="product in filteredProducts"
                        :key="product.id">
                        <product-card
                            showQty
                            :product="product"
                            :allow-add-to-cart="true"></product-card>
                        <v-container fluid class="my-0 py-0">
                            <v-container fluid class="d-flex">
                                <v-btn
                                    class="mr-2"
                                    color="warning"
                                    depressed
                                    rounded
                                    @click="
                                        $router.push({
                                            name: 'ProductUpdate',
                                            params: { id: product.id },
                                        })
                                    ">
                                    <i class="fas fa-pencil-alt mr-2"></i>
                                    Update
                                </v-btn>
                                <v-btn
                                    class="mr-2"
                                    color="warning"
                                    depressed
                                    rounded
                                    @click="addStock(product)">
                                    <i class="fas fa-people-carry mr-2"></i>
                                    Add Stock
                                </v-btn>
                                <v-btn
                                    class="mr-2"
                                    color="primary"
                                    depressed
                                    rounded
                                    @click="
                                        getStocksByProductId(product.id);
                                        getOrdersByProductId(product.id);
                                    ">
                                    <i class="fas fa-download mr-2"></i>
                                    Get Details
                                </v-btn>
                                <v-btn
                                    color="error"
                                    depressed
                                    rounded
                                    @click="openDeleteDialog(product.id)"
                                    :loading="product.id == isDeleteLoadingIdx">
                                    <i class="fa-solid fa-x mr-2"></i>
                                    Remove
                                </v-btn>
                            </v-container>
                            <v-container class="d-flex justify-space-between">
                                <div class="red--text">
                                    <h3>Average Stock Price</h3>
                                </div>
                                <div>
                                    <h3>
                                        ${{ findAvgFromStocks(product.id) }}
                                    </h3>
                                </div>
                            </v-container>
                            <v-container
                                class="rounded-xl"
                                style="border: 1px solid lightgrey">
                                <v-simple-table height="250px">
                                    <thead>
                                        <tr>
                                            <th class="text-left">Id</th>
                                            <th class="text-left">Quantity</th>
                                            <th class="text-left">
                                                Stock Price
                                            </th>
                                            <th class="text-left">
                                                Date Purchased
                                            </th>
                                            <th class="text-left">
                                                Created By
                                            </th>
                                            <th class="text-left">
                                                Date Created
                                            </th>
                                            <th class="text-left">Exp Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="stock in productIdToStocks[
                                                product.id
                                            ]"
                                            :key="`${product.id}-stock-${stock.id}`">
                                            <td>
                                                {{ stock.id }}
                                            </td>
                                            <td>
                                                {{ stock.quantity }}
                                            </td>
                                            <td>
                                                ${{
                                                    convertToCleanNumber(
                                                        stock.stockPrice
                                                    )
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    convertToDate(
                                                        stock.datePurchased
                                                    )
                                                }}
                                            </td>
                                            <td>
                                                {{ stock.createdBy.first }}
                                                {{ stock.createdBy.last }}
                                            </td>
                                            <td>
                                                {{
                                                    convertToDate(
                                                        stock.dateCreated
                                                    )
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    convertToDate(stock.expDate)
                                                }}
                                            </td>
                                            <td>
                                                <v-btn
                                                    color="warning"
                                                    icon
                                                    depressed
                                                    @click="
                                                        updateStock(
                                                            product,
                                                            stock
                                                        )
                                                    "
                                                    :disabled="!stock.isActive"
                                                    ><i
                                                        class="fa-solid fa-gear"></i
                                                ></v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-container>
                        </v-container>
                        <v-container
                            fluid
                            class="w-25 rounded-xl"
                            style="border: 1px solid lightgrey">
                            <v-simple-table height="350px">
                                <thead>
                                    <tr>
                                        <th class="text-left">Order Id</th>
                                        <th class="text-left">Quantity</th>
                                        <th class="text-left">Date Created</th>
                                        <th class="text-left">Invoice</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="order in productIdToOrders[
                                            product.id
                                        ]"
                                        :key="`${product.id}-order-${order.id}`">
                                        <td>
                                            {{ order.id }}
                                        </td>
                                        <td>
                                            {{ order.orderDetails[0].quantity }}
                                        </td>
                                        <td>
                                            {{
                                                convertToDate(order.dateCreated)
                                            }}
                                        </td>
                                        <td>
                                            <span v-if="order.invoiceId">
                                                <a
                                                    @click="
                                                        openInvoice(
                                                            order.invoiceId
                                                        )
                                                    "
                                                    >{{ order.invoiceId }}</a
                                                >
                                            </span>
                                            <span v-else> </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-container>
                    </v-container>
                </v-container>
            </v-card-text>
        </v-card>
        <in-stock-dialog
            v-if="inStockDialog"
            :p-product="selectedProduct"
            :p-stock="selectedStock"
            :is-updating="isUpdatingStock"
            v-model="inStockDialog"
            @handle-event="
                getStocksByProductId(selectedProduct.id)
            "></in-stock-dialog>
        <general-dialog
            :dialog="deleteDialog"
            :options="deleteOptions"
            v-model="deleteDialog"
            @handle-event="deleteProduct"></general-dialog>
        <v-overlay v-model="isLoading">
            <v-progress-circular
                indeterminate
                color="primary"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>
<script>
import backendService from "@/services/backend-service";
import ProductCard from "@/components/ProductCard.vue";
import InStockDialog from "@/components/InStockDialog.vue";
import FilterPanel from "@/components/FilterPanel.vue";
import generalMixin from "@/js/general-mixin";
import GeneralDialog from "@/components/GeneralDialog.vue";
import eventBus from "@/js/event-bus";
import Decimal from "decimal.js";
import { OrderType } from "@/js/enum";
export default {
    name: "StorePage",
    mixins: [generalMixin],
    mounted: function () {
        let query = this.$route.query;
        this.store.id = this.$route.params.id;
        this.getProducts(this.$route.query);
        this.titleFilter = query.titleFilter;
    },
    components: {
        "product-card": ProductCard,
        "in-stock-dialog": InStockDialog,
        "filter-panel": FilterPanel,
        "general-dialog": GeneralDialog,
    },
    data() {
        return {
            expand: false,
            tmpStocks: [],
            productIdToStocks: {},
            productIdToOrders: {},
            selectedProduct: {},
            selectedStock: {},
            products: [],
            store: {},
            isLoadingIdx: -1,
            isDeleteLoadingIdx: -1,
            isLoading: false,
            isUpdatingStock: false,
            inStockDialog: false,
            toDeleteProduct: -1,
            deleteDialog: false,
            deleteOptions: {
                btnText: "Yes",
                message: "This action is not revertable!",
                title: "Are you sure you want to delete this product?",
            },
        };
    },
    computed: {
        cart() {
            return this.$store.getters["cart"];
        },
        cartCount() {
            return this.$store.getters["cartCount"];
        },
        filteredProducts() {
            let products = this.products;
            let searchStr = this.search;
            searchStr = searchStr ? searchStr.trim().toLowerCase() : "";
            if (searchStr && searchStr.length) {
                products = products.filter((e) => {
                    let str = e.title;
                    return str
                        .toString()
                        .trim()
                        .toLowerCase()
                        .includes(searchStr.toLowerCase());
                });
            }

            return products;
        },
        Regular() {
            return this.getIdByEnum(OrderType.Regular);
        },
        search: {
            get() {
                return this.$store.getters["search"];
            },
            set(val) {
                this.$store.commit("setSearch", val);
            },
        },
    },
    methods: {
        addStock(product) {
            this.selectedProduct = product;
            this.inStockDialog = true;
            this.isUpdatingStock = false;
        },
        applyFilter(filter) {
            this.getProducts(filter);
        },
        findAvgFromStocks(productId) {
            if (
                productId &&
                this.productIdToStocks[productId] &&
                this.productIdToStocks[productId].length
            ) {
                let stocks = this.productIdToStocks[productId];
                let sum = new Decimal(0);
                let count = 0;

                for (let i = 0; i < stocks.length; i++) {
                    let stock = stocks[i];
                    let subtotal = Decimal.mul(
                        stock.stockPrice,
                        stock.quantity
                    );
                    sum = sum.plus(subtotal);
                    count += stock.quantity;
                }
                return sum.dividedBy(new Decimal(count)).toFixed(2);
            }
        },
        getProducts(filter) {
            this.isLoading = true;
            backendService.getProducts(filter).then((response) => {
                this.productIdToStocks = {};
                this.timer(1000).then(() => {
                    this.isLoading = false;
                    this.products = response.data;
                    this.products.forEach((product) => {
                        this.productIdToStocks[product.id] = [];
                        this.productIdToOrders[product.id] = [];
                    });
                });
            });
        },
        getStocksByProductId(productId) {
            backendService.getStocksByProductId(productId).then((response) => {
                this.productIdToStocks[productId] = response.data;
                this.$forceUpdate();
            });
        },
        getOrdersByProductId(productId) {
            backendService.getOrdersByProductId(productId).then((response) => {
                this.productIdToOrders[productId] = response.data.filter(
                    (e) => e.typeId == this.Regular
                );
                this.$forceUpdate();
            });
        },
        getStoreById(storeId) {
            backendService.getStoreById(storeId).then((response) => {
                this.store = response.data;
            });
        },
        openDeleteDialog(productId) {
            this.toDeleteProduct = productId;
            this.deleteDialog = true;
        },

        deleteProduct() {
            this.deleteDialog = false;
            this.isDeleteLoadingIdx = this.toDeleteProduct;
            backendService
                .deleteProductById(this.toDeleteProduct)
                .then(() => {
                    setTimeout(() => {
                        this.products = this.products.filter(
                            (x) => x.id != this.toDeleteProduct
                        );
                        this.toDeleteProduct = -1;
                        this.isDeleteLoadingIdx = -1;
                        eventBus.$emit(
                            "setSnackbar",
                            "Successfully delete the product",
                            "success",
                            true
                        );
                    }, 1000);
                })
                .catch(() => {
                    setTimeout(() => {
                        this.isDeleteLoadingIdx = -1;
                        this.toDeleteProduct = -1;
                        eventBus.$emit(
                            "setSnackbar",
                            "Ops!, something is not right!",
                            "error",
                            true
                        );
                    }, 1000);
                });
        },
        openInvoice(invoiceId) {
            let routeData = this.$router.resolve({
                name: "InvoiceUpdate",
                params: { id: invoiceId },
            });
            window.open(routeData.href, "_blank");
        },
        updateStock(product, stock) {
            this.selectedProduct = product;
            this.selectedStock = stock;
            this.inStockDialog = !this.inStockDialog;
            this.isUpdatingStock = true;
        },
    },
    watch: {
        "$route.query": function () {
            this.getProducts(this.$route.query);
        },
    },
};
</script>
