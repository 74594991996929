<template>
    <v-container fluid class="pa-0">
        <v-card class="grey lighten-3">
            <v-card-text>
                <h1>Your Orders</h1>
            </v-card-text>

            <v-card-text
                v-if="orders.length < 1"
                class="grey lighten-2 rounded-xl text-center d-flex flex-column"
                ><h2>Nothing to See</h2>
                <i class="fas fa-spider"></i
            ></v-card-text>
            <v-card-text class="mb-2" elevation="0">
                <v-container>
                    <v-text-field
                        rounded
                        outlined
                        dense
                        label="Search"
                        v-model="orderSearch"
                        clearable></v-text-field>
                </v-container>
                <v-container
                    v-for="order in filteredOrders"
                    :key="`order-${order.id}`"
                    class="mb-2 pa-0 d-flex justify-space-between">
                    <v-container fluid class="white rounded-xl mr-2">
                        <v-container class="d-flex justify-space-between">
                            <v-container>
                                Order #
                                <span>
                                    <br />
                                    <h2>
                                        <a
                                            @click="
                                                $router.push({
                                                    name: 'Shipment.OrderDetail',
                                                    params: { id: order.id },
                                                })
                                            ">
                                            {{ order.id }}
                                        </a>
                                    </h2>
                                </span>
                            </v-container>

                            <v-container>
                                Order Placed
                                <span>
                                    <br />
                                    {{ convertToDate(order.dateCreated) }}
                                </span>
                            </v-container>
                            <v-container>
                                Total
                                <span>
                                    <br />
                                    {{
                                        getCurrencySymbol(
                                            order.orderDetails[0].currencyId
                                        )
                                    }}
                                    {{
                                        calculateTotal(
                                            order.subtotal,
                                            order.deliveryFee
                                        ).toFixed(2)
                                    }}
                                </span>
                            </v-container>
                            <v-container>
                                Invoice #
                                <span v-if="order.invoiceId">
                                    <br />
                                    <a
                                        @click="
                                            $router.push({
                                                name: 'InvoiceUpdate',
                                                params: { id: order.invoiceId },
                                            })
                                        "
                                        >{{ order.invoiceId }}</a
                                    >
                                </span>
                                <span v-else
                                    ><br />
                                    N/A
                                </span>
                            </v-container>
                            <v-container>
                                Order Type
                                <h3
                                    :class="[
                                        {
                                            'primary--text':
                                                order.typeId == Stale,
                                            'warning--text':
                                                order.typeId == PlaceHolder,
                                            'success--text':
                                                order.typeId == Regular,
                                        },
                                    ]">
                                    {{ getOptionName(order.typeId) }}
                                </h3>
                            </v-container>
                        </v-container>
                        <v-divider></v-divider>
                        <v-container>
                            <v-container
                                v-for="orderDetail in order.orderDetails"
                                :key="`order-detail-${order.id}-${orderDetail.id}`"
                                class="d-flex">
                                <img
                                    class="image-preview"
                                    :src="
                                        getProductPictureUrlById(
                                            orderDetail.pictureId
                                        )
                                    " />
                                <v-container class="d-flex flex-column">
                                    <a
                                        @click="
                                            $router.push({
                                                name: 'ProductDetail',
                                                params: {
                                                    id: orderDetail.productId,
                                                },
                                            })
                                        ">
                                        {{ orderDetail.productTitle }}
                                    </a>
                                    <div class="red--text">
                                        {{
                                            getCurrencySymbol(
                                                orderDetail.currencyId
                                            )
                                        }}
                                        {{
                                            convertToCleanNumber(
                                                orderDetail.unitPrice
                                            )
                                        }}
                                    </div>
                                    <div>
                                        <span class="mr-1">{{
                                            getOptionName(
                                                orderDetail.warehouseId
                                            )
                                        }}</span
                                        ><span
                                            >Qty:
                                            {{ orderDetail.quantity }}</span
                                        >
                                    </div>
                                </v-container>
                            </v-container>
                        </v-container>
                    </v-container>
                    <v-container
                        fluid
                        class="w-25 white rounded-xl d-flex flex-column">
                        <v-btn
                            class="mb-2"
                            color="warning"
                            rounded
                            depressed
                            @click="generateInvoice(order)"
                            :disabled="
                                (!!order.invoiceId && order.invoiceId != 0) ||
                                order.typeId != Regular
                            "
                            :loading="order.id == selectedId"
                            ><i class="fas fa-plus mr-2"></i>Generate
                            Invoice</v-btn
                        >
                        <v-btn
                            class="mb-2"
                            color="error"
                            rounded
                            depressed
                            @click="deleteOrder(order)"
                            :loading="order.id == toDeleteId"
                            :disabled="
                                !!order.invoiceId ||
                                order.typeId == Stale ||
                                order.typeId == PlaceHolder
                            "
                            ><i class="fa-solid fa-trash-can mr-2"></i>Delete
                            Order</v-btn
                        >

                        <v-btn
                            class="mb-2"
                            v-if="order.typeId == PlaceHolder"
                            color="primary"
                            rounded
                            depressed
                            @click="unholdOrder(order, warehouseIds[order.id])"
                            :loading="order.id == toUnholdId"
                            :disabled="
                                order.typeId != PlaceHolder ||
                                !warehouseIds[order.id]
                            "
                            ><i class="fa-solid fa-trash-can mr-2"></i>Unhold
                            Order</v-btn
                        >
                        <v-autocomplete
                            v-if="order.typeId == PlaceHolder"
                            dense
                            rounded
                            outlined
                            :items="warehouses"
                            item-text="displayName"
                            item-value="id"
                            label="Back to Warehouse"
                            v-model="warehouseIds[order.id]"></v-autocomplete>
                    </v-container>
                </v-container>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import backendService from "@/services/backend-service.js";
import generalMixin from "@/js/general-mixin";
import Decimal from "decimal.js";
import { InvoiceType, OrderType } from "@/js/enum";
export default {
    name: "OrderList",
    mixins: [generalMixin],
    data() {
        return {
            orders: [],
            toDeleteId: -1,
            toUnholdId: -1,
            isGenerateLoading: false,
            selectedId: -1,
            warehouseIds: {},
        };
    },
    created: function () {
        this.getOrders();
    },
    computed: {
        Regular() {
            return this.getIdByEnum(OrderType.Regular);
        },
        PlaceHolder() {
            return this.getIdByEnum(OrderType.PlaceHolder);
        },
        Stale() {
            return this.getIdByEnum(OrderType.Stale);
        },
        filteredOrders() {
            let orders = this.orders;
            let searchStr = this.orderSearch;
            searchStr = searchStr ? searchStr.trim().toLowerCase() : "";
            if (searchStr && searchStr.length) {
                orders = orders.filter((e) => {
                    let orderDetails = e.orderDetails;
                    for (let i = 0; i < orderDetails.length; i++) {
                        let str = orderDetails[i].productTitle;
                        return str
                            .toString()
                            .trim()
                            .toLowerCase()
                            .includes(searchStr);
                    }
                });
            }

            return orders;
        },
        orderSearch: {
            get() {
                return this.$store.getters["orderSearch"];
            },
            set(val) {
                return this.$store.commit("setOrderSearch", val);
            },
        },
        warehouses() {
            if (this.$store.getters["dropdownToOptions"]["warehouse"]) {
                return this.$store.getters["dropdownToOptions"]["warehouse"]
                    .options;
            }
            return [];
        },
    },
    methods: {
        calculateTotal(subtotal, deliveryFee) {
            return new Decimal(subtotal).plus(new Decimal(deliveryFee));
        },
        deleteOrder(order) {
            this.toDeleteId = order.id;
            backendService
                .deleteOrderById(order.id)
                .then(() => {
                    this.timer(1000).then(() => {
                        this.orders = this.orders.filter(
                            (x) => x.id != order.id
                        );
                        this.toDeleteId = -1;
                        this.successSnackbar("Successfully deleted order");
                    });
                })
                .catch(() => {
                    this.timer(1000).then(() => {
                        this.toDeleteId = -1;
                        this.errorSnackbar("Ops! something is not right");
                    });
                });
        },
        generateInvoice(order) {
            this.selectedId = order.id;
            this.timer(1000).then(() => {
                this.selectedId = order.id;
                this.$router.push({
                    name: "NewInvoice",
                    params: {
                        order: order,
                        isGenerating: true,
                        type: this.getIdByEnum(InvoiceType.Order),
                    },
                });
            });
        },
        getDataText(order) {
            return `${order.id}`;
        },
        getProductPictureUrlById(imageId) {
            return backendService.getProductPictureUrlById(imageId);
        },
        getOrders() {
            backendService.getOrders().then((response) => {
                this.orders = response.data;
            });
        },
        unholdOrder(order, warehouseId) {
            this.toUnholdId = order.id;
            backendService
                .unholdOrder(order.id, warehouseId)
                .then(() => {
                    this.timer(1000).then(() => {
                        this.toUnholdId = -1;
                        order.typeId = this.getIdByEnum(OrderType.Stale);
                        this.successSnackbar("Successfully unholded order");
                    });
                })
                .catch(() => {
                    this.timer(1000).then(() => {
                        this.toUnholdId = -1;
                        this.errorSnackbar("Ops! something is not right");
                    });
                });
        },
    },
};
</script>
<style scoped>
.image-preview {
    object-fit: contain;
    image-rendering: smooth;
    width: 100px;
    height: 100px;
}
</style>
