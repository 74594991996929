<template>
    <v-container fluid class="pa-0">
        <v-data-table :headers="headers" :items="products" :items-per-page="15">
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td>
                            <a
                                @click="
                                    $router.push({
                                        name: 'ProductUpdate',
                                        params: { id: item.id },
                                    })
                                ">
                                {{ item.id }}
                            </a>
                        </td>
                        <td>
                            <img
                                class="image-preview"
                                :src="
                                    getProductPictureUrlById(item.pictureId)
                                " />
                        </td>
                        <td>{{ item.title }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.isActive }}</td>
                        <td>{{ item.dateCreated }}</td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
    </v-container>
</template>
<script>
import backendService from "@/services/backend-service.js";
export default {
    name: "ProductList",
    data() {
        return {
            noDataDialog: false,
            products: [],
            headers: [
                {
                    text: "Id",
                    value: "id",
                },
                { text: "Image", value: "mainImageId" },
                {
                    text: "Title",
                    value: "title",
                },
                {
                    text: "Quantity",
                    value: "quantity",
                },
                { text: "Active", value: "isActive" },
                { text: "Date Created", value: "dateCreated" },
            ],
        };
    },
    mounted: function () {
        this.getProducts();
    },
    computed: {
        stores() {
            return this.$store.getters["stores"];
        },
    },
    methods: {
        getProductPictureUrlById(imageId) {
            return backendService.getProductPictureUrlById(imageId);
        },
        getProducts() {
            backendService.getProductsByStoreId(1).then((response) => {
                this.products = response.data;
            });
        },

        getProduct(idx) {
            this.$router.push({
                name: "ProductDetail",
                params: { id: this.products[idx]._id },
            });
        },

        convertToCleanNumber(raw) {
            return raw.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });
        },
    },
};
</script>
<style scoped>
.image-preview {
    padding-top: 5px;
    object-fit: contain;
    image-rendering: smooth;
    width: 60px;
    height: 60px;
}
.product-row:hover {
    background-color: #ffbe3d;
}
.product-thumbnail {
    width: 500px;
    height: 500px;
    border: 0px;
    object-fit: contain;
    image-rendering: auto;
}
</style>
