var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$route.name != 'Shipment.PrinterFriendly')?_c('v-app-bar',{attrs:{"color":"primary darken-1","dark":"","elevation":"0","dense":"","outlined":""}},[_c('v-container',{staticClass:"d-flex justify-space-around"},[_c('v-btn',{class:{
                    primary: _vm.routeName != 'NewShipment',
                    warning: _vm.routeName == 'NewShipment',
                },attrs:{"depressed":"","rounded":""},on:{"click":function($event){_vm.$router.push({ name: 'NewShipment' }).catch(() => {})}}},[_c('i',{staticClass:"fas fa-plus mr-2"}),_vm._v(" New Shipment ")]),_c('v-btn',{class:{
                    primary: _vm.routeName != 'ShipmentList',
                    warning: _vm.routeName == 'ShipmentList',
                },attrs:{"depressed":"","rounded":""},on:{"click":function($event){_vm.$router.push({ name: 'ShipmentList' }).catch(() => {})}}},[_c('i',{staticClass:"fas fa-cubes mr-2"}),_vm._v(" Shipment List ")]),_c('v-btn',{class:{
                    primary: _vm.routeName != 'NewInvoice',
                    warning: _vm.routeName == 'NewInvoice',
                },attrs:{"depressed":"","rounded":""},on:{"click":function($event){_vm.$router.push({ name: 'NewInvoice' }).catch(() => {})}}},[_c('i',{staticClass:"fas fa-plus mr-2"}),_vm._v(" New Invoice ")]),_c('v-btn',{class:{
                    primary: _vm.routeName != 'InvoiceList',
                    warning: _vm.routeName == 'InvoiceList',
                },attrs:{"depressed":"","rounded":""},on:{"click":function($event){_vm.$router.push({ name: 'InvoiceList' }).catch(() => {})}}},[_c('i',{staticClass:"fas fa-file-invoice mr-2"}),_vm._v(" Invoice List ")]),_c('v-btn',{class:{
                    primary: _vm.routeName != 'NewShipmentGroup',
                    warning: _vm.routeName == 'NewShipmentGroup',
                },attrs:{"depressed":"","rounded":""},on:{"click":function($event){_vm.$router
                        .push({ name: 'NewShipmentGroup' })
                        .catch(() => {})}}},[_c('i',{staticClass:"fas fa-plus mr-2"}),_vm._v("New Shipment Group ")]),_c('v-btn',{class:{
                    primary: _vm.routeName != 'ShipmentGroupList',
                    warning: _vm.routeName == 'ShipmentGroupList',
                },attrs:{"depressed":"","rounded":""},on:{"click":function($event){_vm.$router
                        .push({ name: 'ShipmentGroupList' })
                        .catch(() => {})}}},[_c('i',{staticClass:"fas fa-plane-departure mr-2"}),_vm._v(" Shipment Group List ")]),_c('v-btn',{class:{
                    primary: _vm.routeName != 'NewCustomer',
                    warning: _vm.routeName == 'NewCustomer',
                },attrs:{"depressed":"","rounded":""},on:{"click":function($event){_vm.$router.push({ name: 'NewCustomer' }).catch(() => {})}}},[_c('i',{staticClass:"fas fa-user-plus mr-2"}),_vm._v("New Customer ")]),_c('v-btn',{class:{
                    primary: _vm.routeName != 'CustomerList',
                    warning: _vm.routeName == 'CustomerList',
                },attrs:{"depressed":"","rounded":""},on:{"click":function($event){_vm.$router.push({ name: 'CustomerList' }).catch(() => {})}}},[_c('i',{staticClass:"fas fa-users mr-2"}),_vm._v(" Customer List ")])],1)],1):_vm._e(),(_vm.$route.name != 'Shipment.PrinterFriendly')?_c('v-app-bar',{attrs:{"color":"primary darken-1","dark":"","elevation":"0","dense":"","outlined":""}},[_c('v-container',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"mx-2",class:{
                    primary: _vm.routeName != 'NewProduct',
                    warning: _vm.routeName == 'NewProduct',
                },attrs:{"depressed":"","rounded":""},on:{"click":function($event){_vm.$router.push({ name: 'NewProduct' }).catch(() => {})}}},[_c('i',{staticClass:"fas fa-plus mr-2"}),_vm._v(" New Product ")]),_c('v-btn',{staticClass:"mx-2",class:{
                    primary: _vm.routeName != 'Shipment.StorePage',
                    warning: _vm.routeName == 'Shipment.StorePage',
                },attrs:{"depressed":"","rounded":""},on:{"click":function($event){_vm.$router
                        .push({
                            name: 'Shipment.StorePage',
                        })
                        .catch(() => {})}}},[_c('i',{staticClass:"fas fa-store mr-2"}),_vm._v(" Products ")]),_c('v-btn',{staticClass:"mx-2",class:{
                    primary: _vm.routeName != 'Shipment.Cart',
                    warning: _vm.routeName == 'Shipment.Cart',
                },attrs:{"depressed":"","rounded":""},on:{"click":function($event){_vm.$router.push({ name: 'Shipment.Cart' }).catch(() => {})}}},[_c('i',{staticClass:"fas fa-shopping-cart mr-2"}),_vm._v(" Cart ("+_vm._s(_vm.cartCount)+") ")]),_c('v-btn',{staticClass:"mx-2",class:{
                    primary: _vm.routeName != 'Shipment.OrderList',
                    warning: _vm.routeName == 'Shipment.OrderList',
                },attrs:{"depressed":"","rounded":""},on:{"click":function($event){_vm.$router
                        .push({ name: 'Shipment.OrderList' })
                        .catch(() => {})}}},[_c('i',{staticClass:"fas fa-th-large mr-2"}),_vm._v(" Order List ")]),_c('v-btn',{staticClass:"mx-2",class:{
                    primary: _vm.routeName != 'Shipment.ContactUs',
                    warning: _vm.routeName == 'Shipment.ContactUs',
                },attrs:{"depressed":"","rounded":""},on:{"click":function($event){_vm.$router
                        .push({ name: 'Shipment.ContactUs' })
                        .catch(() => {})}}},[_c('i',{staticClass:"fa-regular fa-address-book mr-2"}),_vm._v("Contact Us ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }