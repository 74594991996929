<template>
    <v-container class="rounded-xl white mt-5">
        <v-card elevation="0">
            <v-card-title>Our Products</v-card-title>
            <v-card-text class="d-flex flex-wrap justify-space-between">
                <v-container
                    :col-2="!isMobile"
                    v-for="product in products"
                    :key="product.id">
                    <product-card
                        :product="product"
                        :clickable="false"
                        :show-price="false"></product-card>
                </v-container>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import backendService from "@/services/backend-service";
import eventBus from "@/js/event-bus";
//import GeneralSnackbar from "@/components/GeneralSnackbar.vue";
import ProductCard from "@/components/ProductCard.vue";
export default {
    name: "SearchPage",
    mounted: function () {
        this.getProducts();
    },
    components: {
        //"general-snackbar": GeneralSnackbar,
        "product-card": ProductCard,
    },
    data() {
        return {
            products: [],
            store: {},
            isLoadingIdx: -1,
            isLoading: false,
        };
    },
    computed: {
        cart() {
            return this.$store.getters["cart"];
        },
        cartCount() {
            return this.$store.getters["cartCount"];
        },
        isMobile() {
            return this.$store.getters["isMobile"];
        },
    },
    methods: {
        addToCart(product) {
            let cart = this.cart;
            let cartCount = this.cartCount;
            if (!cart[product.id]) {
                let item = { product: product, count: 0 };
                cart[product.id] = item;
            }
            cart[product.id].count += 1;
            this.$store.commit("setCart", cart);
            this.$store.commit("setCartCount", cartCount + 1);

            localStorage.setItem("cartCount", cartCount + 1);
            localStorage.setItem("cart", JSON.stringify(cart));
            this.isLoadingIdx = product.id;
            this.timer(1000).then(() => {
                this.isLoadingIdx = -1;
                eventBus.$emit(
                    "setSnackbar",
                    "Item added to the cart",
                    "success",
                    true
                );
            });
        },
        getProducts() {
            backendService.getProducts().then((response) => {
                this.products = response.data;
            });
        },
    },
};
</script>
