<template>
    <v-dialog v-model="input" max-width="30%">
        <customer-form v-model="input"></customer-form>
    </v-dialog>
</template>
<script>
import CustomerForm from "./CustomerForm.vue";
export default {
    name: "CustomerDialog",
    components: { "customer-form": CustomerForm },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        input: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
    },
};
</script>
