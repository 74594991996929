<template>
    <v-card elevation="0" outlined min-width="350">
        <v-card-title>Product Detail</v-card-title>
        <v-card-text>
            <div class="d-flex justify-center">
                <!-- <h3 class="red--text hot-blink" style="position: absolute; right: 2px">
          Popular
        </h3> -->
                <img
                    class="image-preview"
                    :src="getProductPictureUrlById(product.pictureId)" />
            </div>
            <v-form ref="form" v-model="formValid" lazy-validation>
                <div class="d-flex justify-space-between">
                    <v-text-field
                        :rules="[
                            (v) => !!v || 'Required',
                            (v) => (!!v && v > 0) || 'Must be positive',
                        ]"
                        v-model.number="stock.stockPrice"
                        label="Stock Price"
                        rounded
                        outlined
                        dense></v-text-field>
                    <v-text-field
                        :rules="[
                            (v) => !!v || 'Required',
                            (v) =>
                                (!!v && Number.isInteger(v)) ||
                                'Must be a whole number',
                            (v) => (!!v && v > 0) || 'Must be positive',
                        ]"
                        class="ml-5"
                        v-model.number="stock.quantity"
                        label="Quantity"
                        rounded
                        outlined
                        dense></v-text-field>
                </div>

                <v-autocomplete
                    :rules="[(v) => !!v || 'Required']"
                    :items="suppliers"
                    item-text="displayName"
                    item-value="id"
                    v-model="stock.supplierId"
                    label="Supplier"
                    rounded
                    outlined
                    dense
                    required></v-autocomplete>
                <date-picker
                    v-model="stock.expDate"
                    label="Exp Date"></date-picker>
                <date-picker
                    :rules="[(v) => !!v || 'Required']"
                    v-model="stock.datePurchased"
                    label="Date Purchased"></date-picker>
            </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
            <div>
                <v-btn
                    v-if="isUpdating"
                    color="error"
                    depressed
                    icon
                    @click="deleteStock"
                    :loading="isDeleteLoading"
                    ><i class="fa-solid fa-trash-can"></i
                ></v-btn>
            </div>
            <div>
                <v-btn
                    v-if="!isUpdating"
                    class="primary"
                    depressed
                    rounded
                    @click="addStock"
                    :loading="isLoading"
                    :disabled="!formValid"
                    ><i class="fa-solid fa-plus mr-2"></i>Add</v-btn
                >
                <v-btn
                    v-else
                    class="primary"
                    depressed
                    rounded
                    @click="updateStock"
                    :loading="isLoading"
                    :disabled="!formValid">
                    <i class="fas fa-pencil-alt mr-2"></i>Update</v-btn
                >
            </div>
        </v-card-actions>
    </v-card>
</template>
<script>
import DatePicker from "@/components/DatePicker.vue";
import generalMixin from "@/js/general-mixin";
import backendService from "@/services/backend-service";
export default {
    name: "InStockForm",
    mixins: [generalMixin],
    components: { "date-picker": DatePicker },
    mounted: function () {
        if (this.isUpdating) {
            this.stock = { ...this.pStock };
        }
    },
    props: {
        pProduct: {
            type: Object,
            default: () => {
                return {};
            },
        },
        pStock: {
            type: Object,
            default: () => {
                return {};
            },
        },
        isUpdating: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formValid: false,
            isDeleteLoading: false,
            isLoading: false,
            stock: {},
        };
    },
    computed: {
        suppliers() {
            if (
                this.$store.getters["dropdownToOptions"] &&
                this.$store.getters["dropdownToOptions"]["supplier"]
            ) {
                return this.$store.getters["dropdownToOptions"]["supplier"]
                    .options;
            }
            return [];
        },
        product: {
            get() {
                return this.pProduct;
            },
            set(val) {
                this.$emit("set-product", val);
            },
        },
    },

    methods: {
        addStock() {
            this.isLoading = true;
            this.stock.productId = this.product.id;
            backendService.addStock(this.stock).then((response) => {
                this.product.usQuantity = response.data;
                this.product.quantity =
                    this.product.usQuantity + this.product.vnQuantity;
                this.timer(1000).then(() => {
                    this.isLoading = false;
                    this.stock = {};
                    this.$emit("handle-event");
                });
            });
        },
        deleteStock() {
            this.isDeleteLoading = true;
            backendService.deleteStockById(this.stock.id).then((response) => {
                this.product.usQuantity = response.data;
                this.product.quantity =
                    this.product.usQuantity + this.product.vnQuantity;
                this.timer(1000).then(() => {
                    this.isDeleteLoading = false;
                    this.$emit("handle-event");
                });
            });
        },
        updateStock() {
            this.isLoading = true;
            this.stock.updateQty = true;
            backendService.updateStock(this.stock).then((response) => {
                this.product.usQuantity = response.data;
                this.product.quantity =
                    this.product.usQuantity + this.product.vnQuantity;
                this.timer(1000).then(() => {
                    this.isLoading = false;
                    this.stock = {};
                    this.$emit("handle-event");
                });
            });
        },
        forcePositiveNumber(field) {
            if (!isNaN(this.stock[field] && this.stock[field] < 0)) {
                this.stock[field] = 0;
            }
        },
        getProductPictureUrlById(imageId) {
            return backendService.getProductPictureUrlById(imageId);
        },
    },
};
</script>
<style scoped>
.image-preview {
    object-fit: contain;
    image-rendering: smooth;
    width: 150px;
    height: 150px;
}
</style>
