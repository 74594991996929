import Vue from "vue";
import Vuex from "vuex";
import backendService from "@/services/backend-service.js";
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		cart: {},
		cartCount: 0,
		dropdownToOptions: {},
		enumToOption:{},
		idToOption : {
		},
		currentUser: {},
		isLoggedIn: false,
		isAdmin:false,
		isMobile:false,
		stores: [],
		search:"",
		orderSearch:"",
		customers:[],
		orders:[],
		shipments:[],
		shipmentGroups:[],

	},
	getters: {
		cart: (state) => state.cart,
		cartCount: (state) => state.cartCount,
		dropdownToOptions: (state) => state.dropdownToOptions,
		idToOption:(state) => state.idToOption,
		enumToOption:(state) => state.enumToOption,
		currentUser: (state) => state.currentUser,
		isLoggedIn: (state) => state.isLoggedIn,
		isAdmin:(state) => state.isAdmin,
		isMobile:(state) => state.isMobile,
		stores: (state) => state.stores,
		customers:(state) => state.customers,
		orders:(state) => state.orders,
		shipmentGroups:(state) => state.shipmentGroups,
		shipments:(state) => state.shipments,
		search:(state) => state.search,
		orderSearch:(state) => state.orderSearch
	},
	mutations: {
		addCustomer(state,payload) {
			state.customers.unshift(payload);
		},
		addOrder(state,payload) {
			state.orders.unshift(payload);
		},
		addShipment(state,payload) {
			state.shipments.unshift(payload);
		},
		addShipmentGroup(state,payload) {
			state.shipmentGroups.unshift(payload)
		},
		setCart(state, payload) {
			state.cart = payload;
		},
		setCartCount(state, payload) {
			state.cartCount = payload;
		},
		setDropdownToOptions(state, payload) {
			state.dropdownToOptions = payload;
			for(let key in payload) {
				let options = payload[key].options;
				options.forEach(option => {
					state.idToOption[option.id] = option;
					if(option.customEnum)
						state.enumToOption[option.customEnum] = option;
				})
			}
		},
		setCurrentUser(state, payload) {
			state.currentUser = payload;
			state.isLoggedIn = true;
			state.isAdmin = state.currentUser.isAdmin;
		},
		setIsLoggedIn(state, payload) {
			state.isLoggedIn = payload;
		},
		setIsAdmin(state, payload) {
			state.isAdmin = payload;
		},
		setIsMobile(state,payload) {
			state.isMobile = payload
		},
		setOrders(state,payload) {
			state.orders = payload;
		},
		setSearch(state,payload) {
			state.search = payload
		},
		setOrderSearch(state,payload) {
			state.orderSearch = payload
		},
		setStores(state, payload) {
			state.stores = payload;
		},
		setCustomers(state,payload){
			state.customers = payload;
		},
		setShipments(state,payload) {
			state.shipments = payload;
		},
		setShipmentGroups(state,payload) {
			state.shipmentGroups = payload;
		},
		updateCustomer(state,payload) {
			let idx = state.customers.findIndex(x => x.id == payload.id);
			state.customers[idx] = payload;
		},
		updateShipment(state,payload) {
			let idx = state.shipments.findIndex(x => x.id == payload.id);
			state.shipments[idx] = payload;
		},
		updateShipmentGroup(state,payload) {
			let idx = state.shipmentGroups.findIndex(x => x.id == payload.id);
			state.shipmentGroups[idx] = payload;
		}
	},
	actions: {
		getDropdownToOptions(context) {
			backendService.getDropdownToOptions().then((response) => {
				context.commit("setDropdownToOptions", response.data);
			});
		},
		getStoresByUserId(context) {
			backendService
				.getStoresByUserId(context.state.currentUser.id)
				.then((response) => {
					context.commit("setStores", response.data);
				});
		},
		getCustomers(context) {
			backendService.getCustomers().then(response => {
				context.commit("setCustomers",response.data);
			})
		},
		getOrders(context) {
			backendService.getMinimalOrders().then(response => {
				context.commit("setOrders",response.data);
			})
		},
		getShipments(context) {
			backendService.getShipments().then(response => {
				context.commit("setShipments",response.data)
			})
		},
		getShipmentGroups(context) {
			backendService.getShipmentGroups().then(response => {
				context.commit("setShipmentGroups",response.data)
			})
		}

	},
});
