import Vue from "vue";
import VueRouter from "vue-router";
//import Vuex from 'vuex'
// import Home from '../views/Home.vue'
import CategoryPage from "../views/CategoryPage.vue";
import ProductDetail from "../views/ProductDetail.vue";

// import ProductThumbnail from '../views/ProductThumbnail.vue';
import ShoppingCart from "../views/ShoppingCart.vue";
import OrderList from '../views/OrderList.vue';
import OrderDetail from '../views/OrderDetail.vue';
// import OrderDeliveryInfo from '../views/OrderDeliveryInfo.vue';
import HomePage from "@/views/HomePage.vue";
import EmailVerify from "@/views/VerifyAccount.vue";
import LoginPage from "@/views/LoginPage.vue";
import LogoutPage from "@/views/LogoutPage.vue";
// import CustomerOrderList from '../views/CustomerOrderList.vue';
// import ContactAdmin from '../views/ContactAdmin.vue';
// import VerifyAccount from '../views/VerifyAccount.vue';
// import Ads from '../views/Ads.vue';
import SearchPage from "@/views/SearchPage.vue"
import ProductForm from "@/views/ProductForm.vue";
import ProductList from "../views/ProductList.vue";
import StoreRegister from "@/views/StoreRegister.vue";
import StorePage from "@/views/StorePage.vue";
import UserRegister from "@/views/UserRegister.vue";
import StoreList from "@/views/StoreList.vue";
import ShipmentManagement from "@/views/ShipmentManagement.vue";
import ShipmentList from "@/views/ShipmentList.vue";
import ShipmentForm from "@/views/ShipmentForm.vue";
import ShipmentGroup from "@/views/ShipmentGroup.vue";
import ShipmentGroupList from "@/views/ShipmentGroupList.vue";
import PrinterFriendly from "@/views/PrinterFriendly.vue";
import CustomerForm from "@/views/CustomerForm.vue";
import CustomerList from "@/views/CustomerList.vue";
import InvoiceForm from "@/views/InvoiceForm.vue";
import InvoiceList from "@/views/InvoiceList.vue";
import TrackingPage from "@/views/TrackingPage.vue";
import ContactUs from "@/views/ContactUs.vue"
import PageCode404 from "@/views/PageCode404.vue";
//import backendService from "../services/backend-service.js";
//import store from "../stores/index.js";
Vue.use(VueRouter);
const routes = [
	{
		path: "/cart",
		name: "ShoppingCart",
		component: ShoppingCart,
	},
	{
		path: "/",
		name: "HomePage",
		component: HomePage,
	},
	{
		path: "/tracking/:id",
		name: "TrackingPage",
		component: TrackingPage,
	},
	{
		path: "/login",
		name: "Login",
		component: LoginPage,
	},
	{
		path: "/logout",
		name: "Logout",
		component: LogoutPage,
	},
	{
		path: "/register/store",
		name: "StoreRegister",
		component: StoreRegister,
	},
	{
		path: "/register/user",
		name: "UserRegister",
		component: UserRegister,
	},
	{
		path: "/verify/email/:sessionId",
		name: "EmailVerify",
		component: EmailVerify,
	},
	// {
	// 	path: "/add/product/:storeId",
	// 	name: "ProductForm",
	// 	component: ProductForm,
	// },
	// {
	// 	path: "/product/detail/:id",
	// 	name: "ProductDetail",
	// 	component: ProductDetail,
	// },
	// {
	// 	path: "/update/product/:id",
	// 	name: "ProductUpdate",
	// 	component: ProductForm,
	// },
	{
		path: "/search/page",
		name: "SearchPage",
		component: SearchPage,
	},
	
	{
		path:"/view/category/:id",
		name:"CategoryPage",
		component:CategoryPage,
	},
	{
		path:"/store/list",
		name:"StoreList",
		component:StoreList,
	},
	{
		path:"/view/store/:id",
		name:"StorePage",
		component:StorePage,
	},
	{
		path:"/page/404",
		name:"Page404",
		component:PageCode404,
	},
	// {
	// 	path:"/order/:id",
	// 	name:"OrderDetail",
	// 	component:OrderDetail,
	// },
	{
		path:"/shipment/management/",
		name:"ShipmentManagement",
		redirect: {name: 'ShipmentList'},
		component:ShipmentManagement,
		beforeEnter: (to,from,next) => {
			let val = localStorage.getItem("isAdmin")
			if(val === 'true') {
				next()
			}
			else {
				next("/page/404")
			}
			
		},
		children:[
			{
				path:"",
				name:"ShipmentList",
				component:ShipmentList
			},
			{
				path:"new/shipment",
				name:"NewShipment",
				component:ShipmentForm
			},
			{
				path: "update/shipment/:id",
				name: "ShipmentUpdate",
				component: ShipmentForm,
			},
			{
				path:"new/shipment/group",
				name:"NewShipmentGroup",
				component:ShipmentGroup
			},
			{
				path:"update/shipment/group/:id",
				name:"ShipmentGroupUpdate",
				component:ShipmentGroup
			},
			{
				path:"shipment/group/list",
				name:"ShipmentGroupList",
				component:ShipmentGroupList
			},
			{
				path:"new/customer",
				name:"NewCustomer",
				component:CustomerForm
			},
			{
				path:"update/customer/:id",
				name:"CustomerUpdate",
				component:CustomerForm
			},
			{
				path:"customer/list",
				name:"CustomerList",
				component:CustomerList
			},
			{
				path:"new/invoice",
				name:"NewInvoice",
				component:InvoiceForm,
				props:(route) => ({
					...route.params
				})
			},
			{
				path:"update/invoice/:id",
				name:"InvoiceUpdate",
				component:InvoiceForm
			},
			{
				path:"invoice/list",
				name:"InvoiceList",
				component:InvoiceList
			},
			{
				path:"new/product",
				name:"NewProduct",
				component:ProductForm
			},
			{
				path:"product/detail/:id",
				name:"ProductDetail",
				component:ProductDetail,
			},
			{
				path:"update/product/:id",
				name:"ProductUpdate",
				component:ProductForm
			},
			{
				path:"product/list",
				name:"ProductList",
				component:ProductList
			},
			{
				path:"store/page/",
				name:"Shipment.StorePage",
				component:StorePage
			},
			{
				path:"cart",
				name:"Shipment.Cart",
				component:ShoppingCart
			},
			{
				path:"orders",
				name:"Shipment.OrderList",
				component:OrderList
			},
			{
				path:"order/:id",
				name:"Shipment.OrderDetail",
				component:OrderDetail,
			},
			{
				path:"contact/us",
				name:"Shipment.ContactUs",
				component:ContactUs,
			},
			{
				path:"printer/friendly/:id",
				name:"Shipment.PrinterFriendly",
				component:PrinterFriendly,
			},
		]
	},
	
	//   {
	//     path: "/Inventory",
	//     name: "Inventory",
	//     component: Inventory,
	//     beforeEnter: (to, from, next) => {
	//       if (localStorage.getItem("currentAccount")) {
	//         let currentAccount = JSON.parse(localStorage.getItem("currentAccount"));
	//         backendService
	//           .verifyToken(currentAccount.token)
	//           .then((response) => {
	//             store.commit("setIsLoggedIn", true);
	//             store.commit("setCurrentUser", currentAccount);
	//             let role = response.data;
	//             if (role == "admin") {
	//               next();
	//             } else {
	//               next({ name: "ContactAdmin" });
	//             }
	//           })
	//           .catch((err) => {
	//             localStorage.setItem("redirect", window.location);
	//             next({ name: "Login" });
	//           });
	//       } else {
	//         localStorage.setItem("redirect", window.location);
	//         next({ name: "Login" });
	//       }
	//     },
	//   },
	//   {
	//     path: "/Inventory/:id",
	//     name: "Inventory",
	//     component: Inventory,
	//     beforeEnter: (to, from, next) => {
	//       if (localStorage.getItem("currentAccount")) {
	//         let currentAccount = JSON.parse(localStorage.getItem("currentAccount"));
	//         backendService
	//           .verifyToken(currentAccount.token)
	//           .then((response) => {
	//             store.commit("setIsLoggedIn", true);
	//             store.commit("setCurrentUser", currentAccount);
	//             let role = response.data;
	//             if (role == "admin") {
	//               next();
	//             } else {
	//               next({ name: "ContactAdmin" });
	//             }
	//           })
	//           .catch((err) => {
	//             localStorage.setItem("redirect", window.location);
	//             next({ name: "Login" });
	//           });
	//       } else {
	//         localStorage.setItem("redirect", window.location);
	//         next({ name: "Login" });
	//       }
	//     },
	//   },

	//   {
	//     path: "/register/user",
	//     name: "UserRegister",
	//     component: UserRegister,
	//   },
	//   {
	//     path: "/Login",
	//     name: "Login",
	//     component: Login,
	//   },
	//   {
	//     path: "/About",
	//     name: "About",
	//     // route level code-splitting
	//     // this generates a separate chunk (about.[hash].js) for this route
	//     // which is lazy-loaded when the route is visited.
	//     component: function () {
	//       return import(/* webpackChunkName: "about" */ "../views/About.vue");
	//     },
	//   },

	//   {
	//     path: "/ProductList",
	//     name: "ProductList",
	//     component: ProductList,
	//     beforeEnter: (to, from, next) => {
	//       if (localStorage.getItem("currentAccount")) {
	//         let currentAccount = JSON.parse(localStorage.getItem("currentAccount"));
	//         backendService
	//           .verifyToken(currentAccount.token)
	//           .then((response) => {
	//             store.commit("setIsLoggedIn", true);
	//             store.commit("setCurrentUser", currentAccount);
	//             let role = response.data;
	//             if (role == "admin") {
	//               next();
	//             } else {
	//               next({ name: "ContactAdmin" });
	//             }
	//           })
	//           .catch((err) => {
	//             localStorage.setItem("redirect", window.location);
	//             next({ name: "Login" });
	//           });
	//       } else {
	//         localStorage.setItem("redirect", window.location);
	//         next({ name: "Login" });
	//       }
	//     },
	//   },
	//   {
	//     path: "/ProductThumbnail/p/:product",
	//     name: "ProductThumbnail",
	//     component: ProductThumbnail,
	//     props: true,
	//   },
	//   {
	//     path: "/OrderList",
	//     name: "OrderList",
	//     component: OrderList,
	//     beforeEnter: (to, from, next) => {
	//       if (localStorage.getItem("currentAccount")) {
	//         let currentAccount = JSON.parse(localStorage.getItem("currentAccount"));
	//         backendService
	//           .verifyToken(currentAccount.token)
	//           .then((response) => {
	//             store.commit("setIsLoggedIn", true);
	//             store.commit("setCurrentUser", currentAccount);
	//             let role = response.data;
	//             if (role == "admin" || role == "deliveryPerson") {
	//               next();
	//             } else {
	//               next({ name: "ContactAdmin" });
	//             }
	//           })
	//           .catch((err) => {
	//             console.log(err);
	//             localStorage.setItem("redirect", window.location);
	//             next({ name: "Login" });
	//           });
	//       } else {
	//         console.log("no access token");
	//         localStorage.setItem("redirect", window.location);
	//         next({ name: "Login" });
	//       }
	//     },
	//   },
	//   {
	//     path: "/OrderDeliveryInfo/:id",
	//     name: "OrderDeliveryInfo",
	//     component: OrderDeliveryInfo,
	//     beforeEnter: (to, from, next) => {
	//       if (localStorage.getItem("currentAccount")) {
	//         let currentAccount = JSON.parse(localStorage.getItem("currentAccount"));
	//         backendService
	//           .verifyToken(currentAccount.token)
	//           .then((response) => {
	//             store.commit("setIsLoggedIn", true);
	//             store.commit("setCurrentUser", currentAccount);
	//             let role = response.data;
	//             if (role == "admin" || role == "deliveryPerson") {
	//               next();
	//             } else {
	//               next({ name: "ContactAdmin" });
	//             }
	//           })
	//           .catch((err) => {
	//             localStorage.setItem("redirect", window.location);
	//             next({ name: "Login" });
	//           });
	//       } else {
	//         localStorage.setItem("redirect", window.location);
	//         next({ name: "Login" });
	//       }
	//     },
	//   },
	//   {
	//     path: "/CustomerOrderList",
	//     name: "CustomerOrderList",
	//     component: CustomerOrderList,
	//     beforeEnter: (to, from, next) => {
	//       if (localStorage.getItem("currentAccount")) {
	//         let currentAccount = JSON.parse(localStorage.getItem("currentAccount"));
	//         backendService
	//           .verifyToken(currentAccount.token)
	//           .then((response) => {
	//             store.commit("setIsLoggedIn", true);
	//             store.commit("setCurrentUser", currentAccount);
	//             next();
	//           })
	//           .catch((err) => {
	//             localStorage.setItem("redirect", window.location);
	//             next({ name: "Login" });
	//           });
	//       } else {
	//         localStorage.setItem("redirect", window.location);
	//         next({ name: "Login" });
	//       }
	//     },
	//   },
	//   {
	//     path: "/ContactAdmin",
	//     name: "ContactAdmin",
	//     component: ContactAdmin,
	//   },
	//   {
	//     path: "/Verify/:id",
	//     name: "VerifyAccount",
	//     component: VerifyAccount,
	//   },
	//   {
	//     path: "/Ads/:id",
	//     name: "Ads",
	//     component: Ads,
	//   },
];

const router = new VueRouter({
	routes,
});
export default router;
