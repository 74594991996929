<template>
    <v-app>
        <v-container fluid class="pa-0" v-if="$route.name == 'HomePage'">
            <v-img
                v-if="!isMobile"
                style="z-index: 0; position: absolute; top: 0px; width: 100%"
                src="@/assets/background-6.jpg"></v-img>
            <v-container
                fluid
                class="pa-0 white"
                style="
                    z-index: 1;
                    position: absolute;
                    top: 764px;
                "></v-container>
            <!-- <v-container
				fluid
				class="pa-0"
				style="
					z-index: 1;
					position: absolute;
					top: 0px;
					height: 764px;
					background-color: rgba(1, 1, 1, 0.2);
				"></v-container> -->
        </v-container>

        <main-navbar style="z-index: 2"></main-navbar>
        <!-- <secondary-navbar></secondary-navbar> -->
        <v-main
            style="z-index: 3"
            :class="{
                'grey lighten-2': $route.name == 'HomePage',
                'grey lighten-2': $route.name != 'HomePage',
            }">
            <router-view />
        </v-main>
        <!-- <main-footer></main-footer> -->
        <general-snackbar></general-snackbar>
        <v-footer
            :class="{
                'primary darken-1':
                    $route.name != 'HomePage' && $route.name != 'SearchPage',
                'orange darken-2':
                    $route.name == 'HomePage' || $route.name == 'SearchPage',
            }"
            dark>
            <v-container v-if="$route.name == 'Shipment.PrinterFriendly'">
                <div style="font-size: 13px; font-weight: bold">
                    CÁC ĐIỂU KHOẢN GỦI HÀNG (MTL CARGO’S POLICY)
                </div>
                <div style="font-size: 10px">
                    Đối với dịch vụ giao hàng tận nhà, khách hàng sẽ không được
                    bồi thường nếu không kê khai chi tiết và giá trị hàng hoá
                    trong thùng hàng nếu có vấn đề xảy ra trong quá trình vận
                    chuyển. Nếu thùng hàng đã đuọc kí nhận giao phát thành công,
                    mọi thắc mắc khiếu nại sau đó sẽ không được giải quyết. Sẽ
                    không có trường hợp ngoại lệ nào đuọc giải quyết sau 7 ngày
                    kể từ ngày nhận hang. Nếu quý khách không mua bảo hiểm cho
                    hàng hoá của mình, trong trường hợp hàng hoá thất lạc hay hư
                    hại vì bất cứ lý do gì, công ty MTL sẽ chỉ bồi thường tiền
                    vận chuyển và 30% trị giá thùng hàng đã khai báo nhưng không
                    vượt quá $200. Nếu quý khách mua bảo hiểm cho hàng hoá của
                    mình, tiền bảo hiểm sẽ là 5% trị giá thùng hàng. Công ty sẽ
                    không chịu trách nhiệm cho việc hàng hoá bể vỡ hư hại nếu đó
                    là do lỗi của khách hàng, vì thế dối với các mặt hàng dễ vỡ,
                    xin quý khách đóng gói cẩn thận trước khi mang đến văn phòng
                    chúng tôi. Xin quý khách đọc kĩ điều khoản gửi hàng trước
                    khi dời khỏi văn phòng. Mọi thắc mắc sau này sẽ không được
                    giải quyết nếu quý khách không tuân theo dung quy định gửi
                    hàng của công ty.
                </div>
            </v-container>
            <v-container class="d-flex pa-0" style="font-size: 12px">
                <v-container class="text-center"
                    >&copy; {{ currentYear }} MTL Cargo - Chuyen Hang Ve
                    VN</v-container
                >
                <v-container
                    ><h3>CONTACT - LIÊN HỆ</h3>
                    <div>
                        ADDRESS/ ĐỊA CHỈ: 15173 Moran St, Westminister, CA 92683
                    </div>
                    <div>Open: Mon - Fri : 10 AM - 6 PM Sat : 10 AM - 5 PM</div>
                    <div>Phone: (714) 902 - 1432 Cell: (714) 251 - 3832</div>
                    <div>Email: MTLCargo2021@gmail.com</div>
                </v-container>
            </v-container>
        </v-footer>
    </v-app>
</template>

<script>
import MainNavbar from "./components/MainNavbar.vue";
//import SecondaryNavbar from "./components/SecondaryNavbar.vue";
//import MainFooter from "./components/MainFooter.vue";
import backendService from "./services/backend-service";
import GeneralSnackbar from "./components/GeneralSnackbar.vue";
export default {
    name: "App",
    components: {
        "main-navbar": MainNavbar,
        //"secondary-navbar": SecondaryNavbar,
        //"main-footer": MainFooter,
        "general-snackbar": GeneralSnackbar,
    },
    computed: {
        isMobile() {
            return this.$store.getters["isMobile"];
        },
        currentYear() {
            return new Date().getFullYear();
        },
    },
    data: () => ({
        //
    }),
    mounted: function () {
        if (window.innerWidth <= 1000) {
            this.$store.commit("setIsMobile", true);
        }
        if (localStorage.getItem("sessionId")) {
            backendService.verifySession().then((response) => {
                localStorage.setItem("session", JSON.stringify(response.data));
                localStorage.setItem(
                    "userInfo",
                    JSON.stringify(response.data.userInfo)
                );
                localStorage.setItem("isAdmin", response.data.userInfo.isAdmin);
                localStorage.setItem("sessionId", response.data.id);
                this.$store.commit("setCurrentUser", response.data.userInfo);
                this.$store.commit(
                    "setCart",
                    JSON.parse(localStorage.getItem("cart"))
                );
                this.$store.commit(
                    "setCartCount",
                    parseInt(localStorage.getItem("cartCount"))
                );
                if (response.data.userInfo.isAdmin) {
                    this.$store.dispatch("getDropdownToOptions");
                }
            });
        } else {
            localStorage.setItem("cart", JSON.stringify({}));
            localStorage.setItem("cartCount", 0);
        }
    },
};
</script>
<style>
.kh {
    font-family: "Battambang", cursive;
}
.roboto-regular {
    font-family: "Roboto", sans-serif;
    font-style: normal;
}
.v-main {
    font-family: "Ubuntu", sans-serif;
}

/* main {
    background-color: #f1f6f9;
} */
.bg-blue-1 {
    background-color: #212a3e;
}
.bg-blue-2 {
    background-color: #394867 !important;
}
.bg-blue-3 {
    background-color: #9ba4b5;
}
.bg-blue-4 {
    background-color: #f1f6f9;
}
.hot-blink {
    animation: blinker 1s step-start infinite;
}
.w-75 {
    width: 75% !important;
}
.w-50 {
    width: 50% !important;
}
.w-25 {
    width: 25% !important;
}
.no-background {
    background-color: rgba(255 255, 255, 0);
}
@keyframes blinker {
    50% {
        opacity: 0.5;
    }
}
@media print {
    .pagebreak {
        page-break-before: always;
    } /* page-break-after works, as well */
}
</style>
