var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredInvoices,"items-per-page":15},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',_vm._l((_vm.headers),function(header,idx){return _c('th',{key:`header-${idx}`},[_c('v-text-field',{staticClass:"ma-2",attrs:{"dense":"","rounded":"","placeholder":"filter","solo-inverted":"","hide-details":"","flat":""},model:{value:(_vm.search[header.value]),callback:function ($$v) {_vm.$set(_vm.search, header.value, $$v)},expression:"search[header.value]"}})],1)}),0)]},proxy:true},{key:"item",fn:function({ item }){return [_c('tr',[_c('td',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                                name: 'InvoiceUpdate',
                                params: { id: item.id },
                            })}}},[_vm._v(_vm._s(_vm.formatInvoiceId(item)))])]),_c('td',[_vm._v(_vm._s(item.mtlTracking))]),_c('td',[_vm._v(_vm._s(_vm.getCustomerName(item.customerId)))]),_c('td',[_vm._v(_vm._s(item.description))]),_c('td',[_vm._v(" "+_vm._s(_vm.getCurrencySymbol(item.currencyId))+" "+_vm._s(_vm.convertToCleanNumber(item.totalAmount))+" ")]),_c('td',{class:{
                        'green--text': _vm.checkIfPaid(item.paymentStatusId),
                        'red--text': !_vm.checkIfPaid(item.paymentStatusId),
                    }},[_vm._v(" "+_vm._s(_vm.getOptionName(item.paymentStatusId))+" ")]),_c('td',[_vm._v(_vm._s(_vm.convertToDate(item.dateCreated)))])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }