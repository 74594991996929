var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"elevation":"0","outlined":"","min-width":"350"}},[_c('v-card-title',[_vm._v("Product Detail")]),_c('v-card-text',[_c('div',{staticClass:"d-flex justify-center"},[_c('img',{staticClass:"image-preview",attrs:{"src":_vm.getProductPictureUrlById(_vm.product.pictureId)}})]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-text-field',{attrs:{"rules":[
                        (v) => !!v || 'Required',
                        (v) => (!!v && v > 0) || 'Must be positive',
                    ],"label":"Stock Price","rounded":"","outlined":"","dense":""},model:{value:(_vm.stock.stockPrice),callback:function ($$v) {_vm.$set(_vm.stock, "stockPrice", _vm._n($$v))},expression:"stock.stockPrice"}}),_c('v-text-field',{staticClass:"ml-5",attrs:{"rules":[
                        (v) => !!v || 'Required',
                        (v) =>
                            (!!v && Number.isInteger(v)) ||
                            'Must be a whole number',
                        (v) => (!!v && v > 0) || 'Must be positive',
                    ],"label":"Quantity","rounded":"","outlined":"","dense":""},model:{value:(_vm.stock.quantity),callback:function ($$v) {_vm.$set(_vm.stock, "quantity", _vm._n($$v))},expression:"stock.quantity"}})],1),_c('v-autocomplete',{attrs:{"rules":[(v) => !!v || 'Required'],"items":_vm.suppliers,"item-text":"displayName","item-value":"id","label":"Supplier","rounded":"","outlined":"","dense":"","required":""},model:{value:(_vm.stock.supplierId),callback:function ($$v) {_vm.$set(_vm.stock, "supplierId", $$v)},expression:"stock.supplierId"}}),_c('date-picker',{attrs:{"label":"Exp Date"},model:{value:(_vm.stock.expDate),callback:function ($$v) {_vm.$set(_vm.stock, "expDate", $$v)},expression:"stock.expDate"}}),_c('date-picker',{attrs:{"rules":[(v) => !!v || 'Required'],"label":"Date Purchased"},model:{value:(_vm.stock.datePurchased),callback:function ($$v) {_vm.$set(_vm.stock, "datePurchased", $$v)},expression:"stock.datePurchased"}})],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-space-between"},[_c('div',[(_vm.isUpdating)?_c('v-btn',{attrs:{"color":"error","depressed":"","icon":"","loading":_vm.isDeleteLoading},on:{"click":_vm.deleteStock}},[_c('i',{staticClass:"fa-solid fa-trash-can"})]):_vm._e()],1),_c('div',[(!_vm.isUpdating)?_c('v-btn',{staticClass:"primary",attrs:{"depressed":"","rounded":"","loading":_vm.isLoading,"disabled":!_vm.formValid},on:{"click":_vm.addStock}},[_c('i',{staticClass:"fa-solid fa-plus mr-2"}),_vm._v("Add")]):_c('v-btn',{staticClass:"primary",attrs:{"depressed":"","rounded":"","loading":_vm.isLoading,"disabled":!_vm.formValid},on:{"click":_vm.updateStock}},[_c('i',{staticClass:"fas fa-pencil-alt mr-2"}),_vm._v("Update")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }