<template>
    <v-container
        fluid
        class="pa-0"
        :class="{
            'no-background': $route.name == 'HomePage' && !isMobile,
            'primary darken-1':
                $route.name != 'HomePage' && $route.name != 'SearchPage',
            'orange darken-2': $route.name == 'HomePage' && isMobile,
            'orange darken-2': $route.name == 'SearchPage',
        }">
        <v-app-bar
            dark
            style="background-color: rgba(255, 255, 255, 0)"
            elevation="0"
            v-if="$route.name != 'Shipment.PrinterFriendly'">
            <!-- <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon> -->

            <v-toolbar-title
                @click="$router.push({ name: 'HomePage' }).catch(() => {})">
                <v-container class="d-flex align-center" v-if="!isMobile"
                    ><img
                        class="mx-10 mt-3"
                        src="@/assets/mtl-cargo-logo-2.png"
                        style="width: 120px" />

                    <span
                        style="font-family: 'Sora', sans-serif"
                        class="font-weight-black">
                        MTL Cargo - Chuyen Hang Ve VN
                    </span></v-container
                >
                <v-container v-else class="d-flex align-center">
                    <img
                        class="mx-5"
                        src="@/assets/mtl-cargo-logo-2.png"
                        style="width: 70px" />

                    <span
                        v-if="isLoggedIn"
                        style="
                            font-family: 'Sora', sans-serif;
                            font-size: 13px !important;
                        ">
                        MTL Cargo - Chuyen Hang Ve VN
                    </span>
                </v-container>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-text-field
				class="mt-7"
				background-color="white"
				rounded
				filled></v-text-field>
			<v-btn icon><i class="fas fa-search"></i></v-btn> -->
            <v-spacer></v-spacer>

            <v-btn
                v-if="!isLoggedIn"
                text
                dark
                elevation="0"
                @click="$router.push({ name: 'UserRegister' }).catch(() => {})">
                <i class="fas fa-user-plus mr-2"></i>
                Sign Up
            </v-btn>
            <v-btn
                v-if="!isLoggedIn"
                text
                dark
                elevation="0"
                @click="$router.push({ name: 'Login' }).catch(() => {})">
                <i class="fas fa-sign-in-alt mr-2"></i>
                Login
            </v-btn>
            <!-- <v-btn icon @click="$router.push({ name: 'ShoppingCart' })">
				<i class="fas fa-shopping-cart mr-2"></i>
				{{ cartCount }}
			</v-btn> -->
            <v-menu left bottom offset-y min-width="150px" v-if="isLoggedIn">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <!-- <v-list-item
						class="blue--text text--darken-2"
						v-if="isLoggedIn"
						@click="$router.push({ name: 'StoreList' })">
						<v-list-item-title>
							<i class="fas fa-store mr-5"></i>
							Stores
						</v-list-item-title>
					</v-list-item> -->
                    <!-- <v-list-item
						class="blue--text text--darken-2"
						v-if="isLoggedIn"
						@click="$router.push({ name: 'OrderList' })">
						<v-list-item-title>
							<i class="fas fa-boxes mr-5"></i>
							Orders
						</v-list-item-title>
					</v-list-item> -->
                    <!-- <v-list-item
						class="blue--text text--darken-2"
						v-if="isLoggedIn"
						@click="$router.push({ name: 'StoreRegister' })">
						<v-list-item-title>
							<i class="fas fa-store mr-5"></i>
							Register Store
						</v-list-item-title>
					</v-list-item> -->
                    <v-list-item
                        class="blue--text text--darken-2"
                        v-if="isLoggedIn && isAdmin"
                        @click="$router.push({ name: 'ShipmentManagement' })">
                        <v-list-item-title>
                            <i class="fas fa-dolly mr-5"></i>
                            Shipment Manager
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        class="blue--text text--darken-2"
                        v-if="isLoggedIn"
                        @click="logout">
                        <v-list-item-title>
                            <i class="fas fa-sign-out-alt mr-5"></i>
                            Logout
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" absolute temporary>
            <v-list-item>
                <v-list-item-avatar>
                    <i class="fas fa-user-circle fa-3x"></i>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ userFullName }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list dense>
                <v-list-item
                    v-for="(icon, idx) in categories"
                    :key="idx"
                    link
                    @click="
                        $router.push({ name: 'CategoryPage', id: icon.id })
                    ">
                    <v-list-item-icon>
                        <i :class="icon.icon"></i>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ icon.displayName }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <template v-slot:append></template>
        </v-navigation-drawer>
    </v-container>
</template>
<script>
//import bus from "@/bus/bus.js";
import generalMixin from "@/js/general-mixin.js";
import backendService from "@/services/backend-service";
//import backendService from "../services/backend-service";
export default {
    name: "MainNavbar",

    mixins: [generalMixin],
    computed: {
        isMobile() {
            return this.$store.getters["isMobile"];
        },
        cartCount() {
            return this.$store.getters["cartCount"];
        },
        categories() {
            if (
                this.$store.getters["dropdownToOptions"] &&
                this.$store.getters["dropdownToOptions"]["category"]
            )
                return this.$store.getters["dropdownToOptions"]["category"]
                    .options;
            return [];
        },
    },
    data() {
        return {
            drawer: false,
        };
    },
    created: function () {},
    methods: {
        closeNav() {
            document.getElementById("mySidenav").style.width = "0";
            this.showContainer = false;
        },
        openNav() {
            document.getElementById("mySidenav").style.width = "250px";
        },
        showDropdown(dropdown) {
            this.isUncollapsed[dropdown] = !this.isUncollapsed[dropdown];
            // let allElements = document.getElementsByClassName('dropdown-container');
            // console.log(allElements);

            // allElements.forEach(ele => {
            //     ele.style.display = "block";
            // })
        },
        logout() {
            backendService;
            this.$router.push({ name: "Logout" }).catch(() => {});
            // backendService.logout().then(() => {
            //   console.log("session logout");
            // });
        },
    },
};
</script>
<style scoped>
nav .btn {
    color: white;
}
.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    background-color: #3473d9;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.sidenav a,
.dropdown-btn {
    padding: 6px 8px 6px 32px;
    text-decoration: none;
    font-size: 20px;
    color: white;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
}

.sidenav a:hover {
    color: #aaaaaa;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}
.dropdown-container {
    background-color: #516894;
    padding-left: 8px;
}
#categories-dropdown a:first-child {
    font-weight: bold;
}
.brand-title {
    cursor: pointer;
}
</style>
