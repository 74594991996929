<template>
    <v-card class="mb-2 card-border" outlined width="350px">
        <v-card-text class="pt-0 px-0">
            <div class="d-flex justify-center">
                <!-- <h3 class="red--text hot-blink" style="position: absolute; right: 2px">
          Popular
        </h3> -->
                <img
                    class="image-preview"
                    :src="getProductPictureUrlById(product.pictureId)" />
            </div>
            <div
                class="text-center"
                style="
                    width: 100%;
                    height: 2rem;
                    font-size: 12px;
                    line-height: 15px;
                    overflow: hidden;
                ">
                <a
                    @click="
                        if (clickable)
                            $router.push({
                                name: 'ProductDetail',
                                params: { id: product.id },
                            });
                    ">
                    {{ product.title }}
                </a>
            </div>
        </v-card-text>
        <v-card-text class="py-0">
            <div class="d-flex flex-wrap justify-space-between">
                <div v-if="showQty" class="font-weight-bold">
                    <div v-if="showQty">US Qty: {{ product.usQuantity }}</div>
                    <div v-if="showQty">VN Qty: {{ product.vnQuantity }}</div>
                    <v-divider></v-divider>

                    <div v-if="showQty">Total: {{ product.quantity }}</div>
                    <v-divider></v-divider>
                    <div v-if="showQty">
                        Place Holder: {{ product.placeHolderQuantity }}
                    </div>
                </div>

                <span v-else></span>
                <span v-if="showPrice" class="red--text">
                    {{ currencySymbol }}
                    {{ convertToCleanNumber(product.unitPrice) }}
                </span>
            </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
            <v-btn
                v-if="allowAddToCart"
                block
                rounded
                depressed
                color="primary"
                :loading="isLoading"
                @click="addToCart(product)">
                <i class="fas fa-cart-plus mr-2"></i>
                Add to Cart
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import backendService from "@/services/backend-service";
import eventBus from "@/js/event-bus";
import generalMixin from "@/js/general-mixin";
export default {
    name: "ProductCard",
    mixins: [generalMixin],
    props: {
        showQty: {
            type: Boolean,
            default: false,
        },
        showPrice: {
            type: Boolean,
            default: true,
        },
        allowAddToCart: {
            type: Boolean,
            default: false,
        },
        product: {
            type: Object,
            default: () => {
                return {};
            },
        },
        clickable: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        cart() {
            return this.$store.getters["cart"];
        },
        cartCount() {
            return this.$store.getters["cartCount"];
        },
    },
    methods: {
        getProductPictureUrlById(imageId) {
            return backendService.getProductPictureUrlById(imageId);
        },
        addToCart() {
            let cart = this.cart;
            let cartCount = this.cartCount;
            if (!cart[this.product.id]) {
                let item = { product: this.product, count: 0 };
                this.$set(cart, this.product.id, item);
            }
            cart[this.product.id].count += 1;
            this.$store.commit("setCart", cart);
            this.$store.commit("setCartCount", cartCount + 1);

            localStorage.setItem("cartCount", cartCount + 1);
            localStorage.setItem("cart", JSON.stringify(cart));

            this.isLoading = true;
            this.timer(1000).then(() => {
                this.isLoading = false;
                eventBus.$emit(
                    "setSnackbar",
                    "Item added to the cart",
                    "success",
                    true
                );
            });
        },
    },
};
</script>
<style scoped>
.image-preview {
    object-fit: contain;
    image-rendering: smooth;
    width: 150px;
    height: 150px;
}
.card-border {
    border: 1px solid lightgrey;
    border-radius: 5%;
}
.card-border:hover {
    box-shadow: 0px 2px 0px 0px lightgray;
}
</style>
