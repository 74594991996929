<template>
    <v-form ref="form" v-model="formValid">
        <v-container fluid class="d-flex justify-space-between pa-0">
            <v-card elevation="0" class="grey lighten-3" width="50%">
                <v-card-text class="d-flex align-center justify-space-between"
                    ><h1 class="mr-5" v-if="!isUpdating">New Shipment</h1>
                    <div v-else>
                        <h1>Shipment Detail</h1>
                        <h3 class="primary--text font-italic font-weight-thin">
                            <span> #{{ shipment.id }} </span>
                            <span class="ml-2">{{
                                convertToDate(shipment.dateCreated)
                            }}</span>
                        </h3>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="isUpdating && shipment.invoiceId"
                        class="mr-2"
                        color="primary"
                        depressed
                        rounded
                        @click="
                            $router
                                .push({
                                    name: 'InvoiceUpdate',
                                    params: { id: shipment.invoiceId },
                                })
                                .catch(() => {})
                        "
                        ><i class="fa-solid fa-file-invoice mr-2"></i>Go To
                        Invoice</v-btn
                    >
                    <v-btn
                        v-if="isUpdating && shipment.invoiceId"
                        class="mr-2"
                        color="warning"
                        depressed
                        rounded
                        :loading="isUpdateLoading"
                        @click="updateDialog = !updateDialog"
                        ><i class="fa-solid fa-up-long mr-2"></i>Push to
                        Invoice</v-btn
                    >
                    <v-btn
                        class="mr-2"
                        v-if="isUpdating && !shipment.invoiceId"
                        color="warning"
                        depressed
                        rounded
                        @click="generateInvoice"
                        :loading="isGenerateLoading"
                        :disabled="!!shipment.invoiceId"
                        ><i class="fas fa-list mr-2"></i>Generate Invoice</v-btn
                    >

                    <v-btn
                        v-if="isUpdating"
                        class="mr-2"
                        color="warning"
                        depressed
                        rounded
                        @click="updateShipment"
                        :loading="isLoading"
                        :disabled="!formValid"
                        ><i class="fas fa-cubes mr-2"></i>Update Shipment</v-btn
                    >
                    <v-btn
                        v-else
                        class="mr-2"
                        color="primary"
                        depressed
                        rounded
                        @click="addShipment"
                        :loading="isLoading"
                        :disabled="!formValid"
                        ><i class="fas fa-cubes mr-2"></i>Add New
                        Shipment</v-btn
                    >
                    <v-tooltip bottom v-if="isUpdating && !!shipment.invoiceId">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs">
                                <v-btn
                                    v-if="isUpdating"
                                    rounded
                                    depressed
                                    color="error"
                                    :disabled="!!shipment.invoiceId"
                                    ><i class="fa-solid fa-trash-can"></i
                                ></v-btn>
                            </div>
                        </template>
                        <span>Can't delete. Invoice has been created</span>
                    </v-tooltip>
                    <v-btn
                        v-if="isUpdating && !shipment.invoiceId"
                        rounded
                        depressed
                        color="error"
                        @click="deleteDialog = true"
                        :loading="isDeleteLoading"
                        ><i class="fa-solid fa-trash-can"></i></v-btn
                ></v-card-text>

                <v-card-text class="py-0 mb-2">
                    <v-container class="d-flex py-0 my-0 white rounded-xl">
                        <v-container
                            ><v-text-field
                                v-model="shipment.mtlTracking"
                                label="MTL Tracking #"
                                dense
                                rounded
                                outlined></v-text-field
                        ></v-container>
                    </v-container>
                </v-card-text>
                <v-card-text class="py-0 mb-2">
                    <v-container class="d-flex py-0 my-0 white rounded-xl">
                        <v-container>
                            <v-autocomplete
                                :rules="[(v) => !!v || 'Sender is required']"
                                :items="customers"
                                :item-text="getCustomerName"
                                item-value="id"
                                label="Sender"
                                dense
                                rounded
                                outlined
                                v-model="shipment.senderId"
                                @change="updateSenderInfo">
                                <template v-slot:no-data>
                                    <v-card elevation="0">
                                        <v-card-text class="text-center">
                                            <h4 class="mb-2">
                                                Sender not found
                                            </h4>
                                            <v-btn
                                                depressed
                                                small
                                                rounded
                                                color="primary"
                                                @click="
                                                    customerDialog =
                                                        !customerDialog
                                                ">
                                                <i class="fas fa-plus mr-2"></i>
                                                Add Sender
                                            </v-btn>
                                        </v-card-text>
                                    </v-card>
                                </template></v-autocomplete
                            >
                            <v-text-field
                                :rules="[(v) => !!v || 'Address is required']"
                                label="Address Line 1"
                                dense
                                rounded
                                outlined
                                v-model="
                                    shipment.senderAddress1
                                "></v-text-field>
                            <v-text-field
                                label="Address Line 2"
                                dense
                                rounded
                                outlined
                                v-model="
                                    shipment.senderAddress2
                                "></v-text-field>
                            <v-text-field
                                :rules="[(v) => !!v || 'Phone is required']"
                                v-model="shipment.senderPhone"
                                label="Phone"
                                dense
                                rounded
                                outlined></v-text-field>
                            <v-text-field
                                v-model="shipment.senderEmail"
                                label="Email"
                                dense
                                rounded
                                outlined></v-text-field
                        ></v-container>
                        <v-container>
                            <v-autocomplete
                                :rules="[(v) => !!v || 'Receiver is required']"
                                :items="customers"
                                :item-text="getCustomerName"
                                item-value="id"
                                label="Receiver"
                                dense
                                rounded
                                outlined
                                v-model="shipment.receiverId"
                                @change="updateReceiverInfo"
                                ><template v-slot:no-data>
                                    <v-card elevation="0">
                                        <v-card-text class="text-center">
                                            <h4 class="mb-2">
                                                Receiver not found
                                            </h4>
                                            <v-btn
                                                depressed
                                                small
                                                rounded
                                                color="primary"
                                                @click="
                                                    customerDialog =
                                                        !customerDialog
                                                ">
                                                <i class="fas fa-plus mr-2"></i>
                                                Add Receiver
                                            </v-btn>
                                        </v-card-text>
                                    </v-card>
                                </template></v-autocomplete
                            >
                            <v-text-field
                                :rules="[(v) => !!v || 'Address is required']"
                                label="Address Line 1"
                                dense
                                rounded
                                outlined
                                v-model="
                                    shipment.receiverAddress1
                                "></v-text-field>
                            <v-text-field
                                label="Address Line 2"
                                dense
                                rounded
                                outlined
                                v-model="
                                    shipment.receiverAddress2
                                "></v-text-field>
                            <v-text-field
                                :rules="[(v) => !!v || 'Phone is required']"
                                v-model="shipment.receiverPhone"
                                label="Phone"
                                dense
                                rounded
                                outlined></v-text-field>
                            <v-text-field
                                v-model="shipment.receiverEmail"
                                label="Email"
                                dense
                                rounded
                                outlined></v-text-field>
                        </v-container>
                    </v-container>
                </v-card-text>
                <v-card-text class="py-0 mb-2">
                    <v-container
                        class="py-0 my-0 d-flex justify-space-between white rounded-xl">
                        <v-container>
                            <v-text-field
                                :rules="numberRules"
                                v-model.number="shipment.totalBox"
                                label="Number of Boxes"
                                dense
                                rounded
                                outlined></v-text-field>
                        </v-container>
                        <v-container>
                            <v-text-field
                                :rules="numberRules"
                                v-model.number="shipment.totalWeight"
                                label="Total Weight"
                                dense
                                rounded
                                outlined
                                suffix="Lbs"></v-text-field
                        ></v-container>
                        <v-container>
                            <v-text-field
                                :rules="numberRules"
                                v-model.number="shipment.totalValue"
                                prefix="$"
                                label="Total Declared Value"
                                dense
                                rounded
                                outlined></v-text-field
                        ></v-container>
                    </v-container>
                </v-card-text>
                <v-card-text class="py-0 mb-2">
                    <v-container class="py-0 my-0 rounded-xl white">
                        <v-container
                            ><v-textarea
                                rounded
                                outlined
                                v-model="shipment.description"
                                label="Description"></v-textarea
                        ></v-container>
                    </v-container>
                </v-card-text>
            </v-card>
            <v-card elevation="0" class="grey lighten-3" width="49%">
                <v-card-text
                    ><v-container
                        class="d-flex align-center justify-space-between"
                        ><v-autocomplete
                            :rules="[
                                (v) => !!v || 'Shipment Group is required',
                            ]"
                            rounded
                            outlined
                            dense
                            class="mr-2"
                            v-model="shipment.groupId"
                            item-value="id"
                            :item-text="formatShipmentGroup"
                            label="Shipment Group"
                            :items="shipmentGroups"
                            required></v-autocomplete
                        ><v-autocomplete
                            rounded
                            outlined
                            dense
                            v-model="shipment.shipmentStatusId"
                            label="Shipment Status"
                            :items="shipmentStatuses"
                            item-value="id"
                            item-text="displayName"
                            :disabled="!isUpdating"></v-autocomplete
                    ></v-container>
                    <v-container class="white rounded-xl">
                        <v-container><h2>Parcel List</h2></v-container>
                        <v-container
                            v-for="(parcel, idx) in parcels"
                            :key="`parcel-${idx}`"
                            class="py-0 my-0 d-flex justify-space-between">
                            <v-container class="py-0 my-0">
                                <v-text-field
                                    :rules="numberRules"
                                    label="Weight"
                                    dense
                                    rounded
                                    outlined
                                    suffix="Lbs"
                                    v-model.number="
                                        parcel.weight
                                    "></v-text-field
                            ></v-container>
                            <v-container class="py-0 my-0">
                                <v-text-field
                                    :rules="numberRules"
                                    prefix="$"
                                    label="Declared Value"
                                    dense
                                    rounded
                                    outlined
                                    v-model.number="parcel.value"></v-text-field
                            ></v-container>
                            <v-container fluid class="py-0 my-0 w-25">
                                <v-btn
                                    v-if="!parcel.id"
                                    color="red"
                                    depressed
                                    icon
                                    @click="deleteParcel(idx)"
                                    ><i class="fas fa-times"></i
                                ></v-btn>
                                <v-btn
                                    v-if="
                                        parcel.id &&
                                        parcel.isActive &&
                                        isUpdating
                                    "
                                    color="red"
                                    depressed
                                    icon
                                    @click="removeParcel(idx)"
                                    ><i class="fas fa-times"></i
                                ></v-btn>
                                <v-btn
                                    v-if="
                                        parcel.id &&
                                        !parcel.isActive &&
                                        isUpdating
                                    "
                                    color="red"
                                    depressed
                                    icon
                                    @click="undoRemoveParcel(idx)"
                                    ><i
                                        class="fas fa-undo"></i></v-btn></v-container
                        ></v-container>

                        <v-container
                            ><v-btn
                                color="primary"
                                depressed
                                rounded
                                @click="addParcel"
                                ><i class="fas fa-plus mr-2"></i>Add</v-btn
                            ></v-container
                        >
                    </v-container>
                </v-card-text>
            </v-card>
            <customer-dialog v-model="customerDialog"></customer-dialog>
            <general-dialog
                :dialog="deleteDialog"
                v-model="deleteDialog"
                :options="deleteOptions"
                @handle-event="deleteShipment"></general-dialog>
            <general-dialog
                :dialog="updateDialog"
                v-model="updateDialog"
                :options="updateOptions"
                @handle-event="updateInvoice"></general-dialog>
        </v-container>
    </v-form>
</template>
<script>
import eventBus from "@/js/event-bus";
import backendService from "@/services/backend-service";
import generalMixin from "@/js/general-mixin.js";
import { InvoiceType, ShipmentStatus, CurrencyType } from "@/js/enum.js";
import GeneralDialog from "@/components/GeneralDialog.vue";
import CustomerDialog from "@/components/CustomerDialog.vue";
import Decimal from "decimal.js";
export default {
    name: "ShipmentForm",
    mixins: [generalMixin],
    components: {
        "customer-dialog": CustomerDialog,
        "general-dialog": GeneralDialog,
    },
    data() {
        return {
            formValid: false,
            originalShipment: {},
            originalParcels: [],
            isUpdating: false,
            isGenerateLoading: false,
            isLoading: false,
            parcels: [],
            shipment: {
                mtlTracking: null,
                description: "",
                totalBox: 0,
                totalWeight: 0,
                totalValue: 100,
                shipmentStatusId: this.getIdByEnum(ShipmentStatus.New),
            },
            deleteDialog: false,
            isDeleteLoading: false,
            deleteOptions: {
                btnText: "Yes",
                message: "This action is not revertable!",
                title: "Are you sure you want to delete this shipment?",
            },
            updateDialog: false,
            isUpdateLoading: false,
            updateOptions: {
                btnText: "Yes",
                message: "This action is not revertable!",
                title: "Are you sure you want to update the invoice?",
            },
            numberRules: [
                (v) => {
                    if (v) return true;
                    return "Required";
                },
                (v) => {
                    if (!isNaN(v)) return true;
                    return "Must be numeric";
                },
                (v) => {
                    if (v > 0) return true;
                    return "Must be greater than 0";
                },
            ],
            customerDialog: false,
            customer: {},
        };
    },
    mounted: function () {
        if (this.$route.params.id) {
            this.getUpdateForm();
        }
        this.$refs.form.validate();
    },
    computed: {
        customers() {
            return this.$store.getters["customers"];
        },
        shipmentGroups() {
            return this.$store.getters["shipmentGroups"];
        },
        shipments: {
            get() {
                return this.$store.getters["shipments"];
            },
            set(val) {
                this.$store.commit("setShipments", val);
            },
        },
        paymentStatuses() {
            if (
                this.$store.getters["dropdownToOptions"] &&
                this.$store.getters["dropdownToOptions"]["paymentStatus"]
            )
                return this.$store.getters["dropdownToOptions"]["paymentStatus"]
                    .options;
            return [];
        },
        shipmentStatuses() {
            if (
                this.$store.getters["dropdownToOptions"] &&
                this.$store.getters["dropdownToOptions"]["shipmentStatus"]
            )
                return this.$store.getters["dropdownToOptions"][
                    "shipmentStatus"
                ].options;
            return [];
        },
    },
    methods: {
        addParcel() {
            this.parcels.push({ isActive: true, weight: 0, value: 0 });
        },
        addShipment() {
            if (!this.$refs.form.validate()) return;
            this.isLoading = true;
            this.shipment.parcels = this.parcels;
            backendService
                .addShipment(this.shipment)
                .then((response) => {
                    this.shipment = response.data;
                    this.parcels = this.shipment.parcels;
                    this.$store.commit("addShipment", this.shipment);
                    this.timer(1000).then(() => {
                        this.isLoading = false;
                        this.isUpdating = true;
                        this.$router.push({
                            name: "ShipmentUpdate",
                            params: { id: this.shipment.id },
                        });
                        this.successSnackbar(
                            "Successfully added a new shipment"
                        );
                    });
                })
                .catch((err) => {
                    let message = err.response.data;
                    this.timer(1000).then(() => {
                        if (
                            typeof message == "string" &&
                            message.includes("Duplicate")
                        ) {
                            this.errorSnackbar(
                                "Repeated tracking # under shipment list"
                            );
                        } else {
                            this.errorSnackbar("Ops! Something is not right");
                        }
                        this.isLoading = false;
                    });
                });
        },
        deleteShipment() {
            this.isDeleteLoading = true;
            backendService
                .deleteShipmentById(this.shipment.id)
                .then(() => {
                    setTimeout(() => {
                        this.isDeleteLoading = false;
                        this.$router.push({ name: "ShipmentList" });
                        eventBus.$emit(
                            "setSnackbar",
                            "Successfully delete the shipment",
                            "success",
                            true
                        );
                    }, 1000);
                })
                .catch(() => {
                    this.isDeleteLoading = false;
                    eventBus.$emit(
                        "setSnackbar",
                        "Ops! Something is not right!",
                        "error",
                        true
                    );
                }, 1000);
        },
        deleteParcel(idx) {
            this.parcels.splice(idx, 1);
        },
        formatShipmentGroup(shipmentGroup) {
            if (shipmentGroup) {
                return `${shipmentGroup.id} - ${this.convertToDate(
                    shipmentGroup.shipmentDate
                )} - ${this.getOptionName(shipmentGroup.statusId)}`;
            }
        },
        generateInvoice() {
            this.isGenerateLoading = true;
            this.timer(1000).then(() => {
                this.$router.push({
                    name: "NewInvoice",
                    params: {
                        shipment: this.shipment,
                        isGenerating: true,
                        type: this.getIdByEnum(InvoiceType.Shipment),
                    },
                });
            });
        },
        getCustomerName(item) {
            return `${item.id} - ${item.name} - ${item.phone}`;
        },
        getUpdateForm() {
            this.isUpdating = true;
            this.getShipmentById(this.$route.params.id);
        },
        getShipmentById(id) {
            backendService.getShipmentById(id).then((response) => {
                this.shipment = response.data;
                this.parcels = this.shipment.parcels;
                this.originalShipment = JSON.stringify(this.shipment);
            });
        },
        undoRemoveParcel(idx) {
            this.parcels[idx].isActive = true;
        },
        removeParcel(idx) {
            this.parcels[idx].isActive = false;
        },
        updateInvoice() {
            let invoice = {};
            let invoiceDetails = [];
            invoice.invoiceDetails = invoiceDetails;
            invoice.id = this.shipment.invoiceId;
            invoice.customerId = this.shipment.senderId;
            invoice.typeId = this.getIdByEnum(InvoiceType.Shipment);
            invoice.currencyId = this.getIdByEnum(CurrencyType.UsDollar);
            invoice.associatedId = this.shipment.id;
            invoice.mtlTracking = this.shipment.mtlTracking;
            invoice.description = this.shipment.description;
            invoice.totalAmount = Decimal.mul(this.shipment.totalWeight, 2.75);
            let invoiceDetail = {
                name: `Shipment ${this.shipment.id}`,
                quantity: this.shipment.totalWeight,
                unitPrice: 2.75,
                isActive: true,
            };
            invoiceDetails.push(invoiceDetail);

            backendService
                .updateInvoice(invoice)
                .then(() => {
                    this.timer(1000).then(() => {
                        this.isUpdateLoading = false;
                        this.successSnackbar(
                            "Successfully updated the invoice"
                        );
                    });
                })
                .catch(() => {
                    this.errorSnackbar("Ops! Something is not right");
                });
        },
        updateShipment() {
            if (!this.$refs.form.validate()) return;
            this.isLoading = true;
            this.shipment.parcels = this.parcels;
            console.log("check");
            backendService
                .updateShipment(this.shipment)
                .then((response) => {
                    this.$store.commit("updateShipment", this.shipment);
                    this.timer(1000).then(() => {
                        this.shipment = response.data;
                        this.parcels = this.shipment.parcels;
                        this.originalShipment = JSON.stringify(this.shipment);
                        this.isLoading = false;
                        this.successSnackbar(
                            "Successfully updated a new shipment"
                        );
                    });
                })
                .catch((err) => {
                    let message = err.response.data;
                    this.timer(1000).then(() => {
                        if (
                            typeof message == "string" &&
                            message.includes("Duplicate")
                        ) {
                            this.errorSnackbar(
                                "Repeated tracking # under shipment list"
                            );
                        } else {
                            this.errorSnackbar("Ops! Something is not right");
                        }
                        this.isLoading = false;
                    });
                });
        },
        updateSenderInfo() {
            let customer = this.customers.find(
                (x) => x.id == this.shipment.senderId
            );
            this.shipment.senderAddress1 = customer.address1;
            this.shipment.senderAddress2 = customer.address2;
            this.shipment.senderPhone = customer.phone;
            this.shipment.senderEmail = customer.email;
        },
        updateReceiverInfo() {
            let customer = this.customers.find(
                (x) => x.id == this.shipment.receiverId
            );
            this.shipment.receiverAddress1 = customer.address1;
            this.shipment.receiverAddress2 = customer.address2;
            this.shipment.receiverPhone = customer.phone;
            this.shipment.receiverEmail = customer.email;
        },
    },
    watch: {
        "$route.name": {
            handler: function (name) {
                if (name == "NewShipment") {
                    this.isLoading = false;
                    this.isUpdating = false;
                    this.parcels = [];
                    this.shipment = {
                        mtlTracking: "",
                        description: "",
                        totalBox: 0,
                        totalWeight: 0,
                        totalValue: 100,
                        shipmentStatusId: this.getIdByEnum(ShipmentStatus.New),
                    };
                }
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>
