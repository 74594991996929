<template>
    <v-container fluid class="d-flex justify-space-between pa-0">
        <v-card elevation="0" class="grey lighten-3" width="50%">
            <v-card-text class="d-flex align-center justify-space-between"
                ><h1 class="mr-5" v-if="!isUpdating">New Invoice</h1>
                <div v-else>
                    <h1>Invoice Detail</h1>
                    <h3 class="primary--text font-italic font-weight-thin">
                        <span>
                            {{
                                getOptionName(invoice.typeId)[0].toUpperCase()
                            }}-{{ String(invoice.id).padStart(5, "0") }}
                        </span>
                        <span class="ml-2">{{
                            convertToDate(invoice.dateCreated)
                        }}</span>
                    </h3>
                </div>

                <v-spacer></v-spacer>
                <!-- <v-btn
          v-if="isUpdating"
          class="mr-2"
          color="warning"
          depressed
          rounded
          @click="
            paymentDialog = true;
            totalAmount = invoice.totalAmount;
          "
          :disabled="paymentStatusId == paid"
          :loading="isPaymentLoading"
          ><i class="fas fa-dollar-sign mr-2"></i>Make Payment</v-btn
        > -->
                <v-btn
                    v-if="
                        isUpdating &&
                        invoice.associatedId &&
                        invoice.typeId == Shipment
                    "
                    class="mr-2"
                    color="primary"
                    depressed
                    rounded
                    @click="
                        $router
                            .push({
                                name: 'ShipmentUpdate',
                                params: { id: invoice.associatedId },
                            })
                            .catch(() => {})
                    "
                    ><i class="fa-solid fa-file-invoice mr-2"></i>Go To
                    Shipment</v-btn
                >
                <v-btn
                    v-if="isUpdating"
                    class="mr-2"
                    color="warning"
                    depressed
                    rounded
                    @click="updateInvoice"
                    :loading="isLoading"
                    :disabled="!formValid"
                    ><i class="fas fa-cubes mr-2"></i>Update Invoice</v-btn
                >
                <v-btn
                    v-else
                    color="primary"
                    depressed
                    rounded
                    @click="addInvoice"
                    :loading="isLoading"
                    :disabled="!formValid"
                    ><i class="fas fa-cubes mr-2"></i>Add New Invoice</v-btn
                >
                <v-btn
                    v-if="isUpdating"
                    class="mr-2"
                    color="primary"
                    depressed
                    rounded
                    :href="`/#/shipment/management/printer/friendly/${invoice.id}`"
                    target="_blank"
                    ><i class="fas fa-cubes mr-2"></i>Printer Friendly</v-btn
                >
                <v-tooltip bottom v-if="isUpdating && paymentStatusId == Paid">
                    <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" v-bind="attrs">
                            <v-btn
                                v-if="isUpdating"
                                rounded
                                depressed
                                color="error"
                                :disabled="paymentStatusId == Paid"
                                ><i class="fa-solid fa-trash-can"></i
                            ></v-btn>
                        </div>
                    </template>
                    <span>Can't delete. Payment has been made</span>
                </v-tooltip>
                <v-btn
                    v-if="isUpdating && paymentStatusId != Paid"
                    rounded
                    depressed
                    color="error"
                    @click="deleteDialog = true"
                    :disabled="paymentStatusId == Paid"
                    :loading="isDeleteLoading"
                    ><i class="fa-solid fa-trash-can"></i
                ></v-btn>
            </v-card-text>
            <v-form ref="form" v-model="formValid">
                <v-card-text class="py-0 mb-2">
                    <v-container class="white rounded-xl d-flex"
                        ><v-container>
                            <v-text-field
                                rounded
                                outlined
                                dense
                                label="Tracking #"
                                v-model="invoice.mtlTracking"></v-text-field>
                        </v-container>

                        <v-container>
                            <v-autocomplete
                                rounded
                                outlined
                                dense
                                :rules="dropdownRules"
                                :items="currencyTypes"
                                item-text="displayName"
                                item-value="id"
                                label="Currency Type"
                                v-model.number="currencyId"
                                :disabled="paymentStatusId == Paid"
                                required></v-autocomplete>
                        </v-container>
                    </v-container>
                </v-card-text>
                <v-card-text class="py-0 mb-2">
                    <v-container
                        class="d-flex justify-space-between white rounded-xl">
                        <v-container>
                            <v-autocomplete
                                :rules="[(v) => !!v || 'Type is required']"
                                rounded
                                outlined
                                dense
                                label="Invoice Type"
                                :items="invoiceTypes"
                                item-text="displayName"
                                item-value="id"
                                @change="
                                    $refs['form'].validate();
                                    invoice.associatedId = null;
                                "
                                :disabled="paymentStatusId == Paid"
                                v-model="invoice.typeId"></v-autocomplete>
                            <v-autocomplete
                                :rules="[(v) => !!v || 'Customer is required']"
                                rounded
                                outlined
                                dense
                                label="Customer"
                                item-value="id"
                                :items="customers"
                                :item-text="getCustomerName"
                                v-model="invoice.customerId"
                                required
                                ><template v-slot:no-data>
                                    <v-card elevation="0">
                                        <v-card-text class="text-center">
                                            <h4 class="mb-2">
                                                Customer not found
                                            </h4>
                                            <v-btn
                                                depressed
                                                small
                                                rounded
                                                color="primary"
                                                @click="
                                                    customerDialog =
                                                        !customerDialog
                                                ">
                                                <i class="fas fa-plus mr-2"></i>
                                                Add Customer
                                            </v-btn>
                                        </v-card-text>
                                    </v-card>
                                </template></v-autocomplete
                            >
                        </v-container>
                        <v-container>
                            <v-autocomplete
                                :rules="[
                                    (v) => {
                                        if (invoice.typeId == general || !!v)
                                            return true;
                                        else if (invoice.typeId && !v)
                                            return 'Required';
                                        return true;
                                    },
                                ]"
                                rounded
                                outlined
                                dense
                                :items="items"
                                item-value="id"
                                item-text="id"
                                label="Id"
                                v-model="invoice.associatedId"
                                :disabled="paymentStatusId == Paid"
                                required></v-autocomplete>

                            <v-text-field
                                :rules="[
                                    (v) =>
                                        v > 0 ||
                                        'Total Amount Due must be greater than 0',
                                    (v) =>
                                        !!v || 'Total Amount Due is required',
                                ]"
                                :prefix="currencySymbol"
                                rounded
                                outlined
                                dense
                                label="Total Amount Due"
                                :disabled="paymentStatusId == Paid"
                                v-model="invoice.totalAmount"></v-text-field>
                        </v-container>
                    </v-container>
                </v-card-text>

                <v-card-text class="py-0 mb-2">
                    <v-container class="white rounded-xl"
                        ><v-textarea
                            rounded
                            outlined
                            label="Description"
                            v-model="invoice.description"></v-textarea
                    ></v-container>
                </v-card-text>
            </v-form>
        </v-card>
        <v-card elevation="0" class="grey lighten-3" width="50%">
            <v-card-text v-if="isUpdating" class="py-0 mt-2 mb-2">
                <v-container class="white rounded-t-xl py-0"
                    ><v-container class="d-flex justify-space-between">
                        <v-container>
                            <h2>Payment Info</h2>

                            <h3
                                v-if="paymentStatusId == Paid"
                                class="mr-2 font-italic font-weight-thin">
                                Collected by:
                                {{
                                    `${payment.collector.first} ${payment.collector.last}`
                                }}
                            </h3>
                        </v-container>
                        <v-container
                            class="d-flex justify-end w-75 align-center">
                            <v-btn
                                color="warning"
                                depressed
                                rounded
                                @click="submitPayment"
                                :loading="isPaymentLoading"
                                :disabled="
                                    paymentStatusId == Paid ||
                                    (!payment.typeId && !payment.statusId)
                                "
                                ><i class="fas fa-cubes mr-2"></i>Make
                                Payment</v-btn
                            ></v-container
                        >
                    </v-container></v-container
                >
                <v-container
                    class="white rounded-b-xl d-flex justify-space-between">
                    <v-container>
                        <v-autocomplete
                            rounded
                            outlined
                            dense
                            :items="paymentStatuses"
                            item-text="displayName"
                            item-value="id"
                            label="Payment Status"
                            :disabled="paymentStatusId == Paid"
                            v-model="invoice.paymentStatusId"></v-autocomplete>
                    </v-container>
                    <v-container>
                        <v-autocomplete
                            rounded
                            outlined
                            dense
                            :items="paymentTypes"
                            item-text="displayName"
                            item-value="id"
                            label="Payment Type"
                            :disabled="paymentStatusId == Paid"
                            v-model="payment.typeId"></v-autocomplete>
                    </v-container>
                    <v-container v-if="paymentStatusId == Paid">
                        <v-text-field
                            outlined
                            rounded
                            dense
                            label="Payment Date"
                            :value="paymentDate"
                            :disabled="true"></v-text-field>
                    </v-container>
                </v-container>
            </v-card-text>
            <v-card-text class="py-0">
                <v-container class="white rounded-xl">
                    <v-container><h2>Charge List</h2></v-container>
                    <v-container
                        v-for="(invoiceDetail, idx) in invoiceDetails"
                        :key="`invoice-detail-${idx}`"
                        class="d-flex justify-space-between">
                        <v-container class="py-0 my-0">
                            <v-text-field
                                :label="`Item ${idx + 1}`"
                                dense
                                rounded
                                outlined
                                v-model="invoiceDetail.name"
                                :disabled="
                                    paymentStatusId == Paid
                                "></v-text-field
                        ></v-container>
                        <v-container fluid class="py-0 my-0 w-25">
                            <v-text-field
                                label="Qty"
                                dense
                                rounded
                                outlined
                                v-model.number="invoiceDetail.quantity"
                                @change="updateTotalAmount"
                                :disabled="
                                    paymentStatusId == Paid
                                "></v-text-field
                        ></v-container>
                        <v-container fluid class="py-0 my-0 w-50">
                            <v-text-field
                                :prefix="currencySymbol"
                                label="Unit Price"
                                dense
                                rounded
                                outlined
                                v-model.number="invoiceDetail.unitPrice"
                                @change="updateTotalAmount"
                                :disabled="
                                    paymentStatusId == Paid
                                "></v-text-field
                        ></v-container>

                        <v-container fluid class="py-0 my-0 w-25">
                            <v-btn
                                v-if="!isUpdating || !invoiceDetail.id"
                                color="red"
                                depressed
                                icon
                                @click="deleteInvoiceDetail(idx)"
                                ><i class="fas fa-times"></i
                            ></v-btn>
                            <v-btn
                                v-if="
                                    invoiceDetail.id &&
                                    invoiceDetail.isActive &&
                                    isUpdating
                                "
                                color="red"
                                depressed
                                icon
                                @click="removeInvoiceDetail(idx)"
                                :disabled="paymentStatusId == Paid"
                                ><i class="fas fa-times"></i
                            ></v-btn>
                            <v-btn
                                v-if="
                                    invoiceDetail.id &&
                                    !invoiceDetail.isActive &&
                                    isUpdating
                                "
                                color="red"
                                depressed
                                icon
                                @click="undoRemoveInvoiceDetail(idx)"
                                ><i
                                    class="fas fa-undo"></i></v-btn></v-container
                    ></v-container>

                    <v-container
                        ><v-btn
                            color="primary"
                            depressed
                            rounded
                            @click="addInvoiceDetail"
                            :disabled="paymentStatusId == Paid"
                            ><i class="fas fa-plus mr-2"></i>Add</v-btn
                        ></v-container
                    >
                </v-container>
            </v-card-text>
        </v-card>
        <general-dialog
            :dialog="deleteDialog"
            :options="deleteOptions"
            v-model="deleteDialog"
            @handle-event="deleteInvoice"></general-dialog>
        <payment-dialog
            v-model="paymentDialog"
            @handle-event="openPaymentDialog"></payment-dialog>
        <cash-dialog
            v-model="cashDialog"
            :p-total-amount="totalAmount * 100"
            @total-amount="(val) => (totalAmount = val)"
            @handle-event="submitPayment"></cash-dialog>
        <customer-dialog v-model="customerDialog"></customer-dialog>
        <v-dialog v-model="digitalDialog" max-width="600">
            <v-card class="py-5 rounded-xl">
                <v-card-text class="d-flex justify-space-between">
                    <span><h2>Amount Due</h2></span>
                    <span class="red--text"
                        ><h2>$ {{ invoice.totalAmount }}</h2></span
                    >
                </v-card-text>
                <v-card-text class="d-flex justify-space-between">
                    <h1>Please use Debit/Credit machine</h1>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        rounded
                        depressed
                        color="primary"
                        @click="submitDigitalPayment"
                        >Okay</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import eventBus from "@/js/event-bus";
import backendService from "@/services/backend-service";
import generalMixin from "@/js/general-mixin.js";
import PaymentDialog from "@/components/PaymentDialog.vue";
import CashDialog from "@/components/CashDialog.vue";
import GeneralDialog from "@/components/GeneralDialog.vue";
import CustomerDialog from "@/components/CustomerDialog.vue";
import Decimal from "decimal.js";
import {
    InvoiceType,
    PaymentType,
    PaymentStatus,
    CurrencyType,
} from "@/js/enum";
export default {
    name: "InvoiceForm",
    mixins: [generalMixin],
    components: {
        "payment-dialog": PaymentDialog,
        "cash-dialog": CashDialog,
        "general-dialog": GeneralDialog,
        "customer-dialog": CustomerDialog,
    },
    mounted: function () {
        if (this.$route.params.id) {
            this.getUpdateForm();
        } else if (this.isGenerating) {
            if (this.type == this.getIdByEnum(InvoiceType.Shipment)) {
                this.invoice.customerId = this.shipment.senderId;
                this.invoice.typeId = this.getIdByEnum(InvoiceType.Shipment);
                this.currencyId = this.getIdByEnum(CurrencyType.UsDollar);
                this.invoice.associatedId = this.shipment.id;
                this.invoice.mtlTracking = this.shipment.mtlTracking;
                let invoiceDetail = {
                    name: `Shipment ${this.shipment.id}`,
                    quantity: this.shipment.totalWeight,
                    unitPrice: 2.75,
                    isActive: true,
                };
                this.invoiceDetails.push(invoiceDetail);
            } else if (this.type == this.getIdByEnum(InvoiceType.Order)) {
                this.invoice.typeId = this.type;
                this.invoice.associatedId = this.order.id;
                this.invoice.mtlTracking = this.order.mtlTracking;
                this.order.orderDetails.forEach((orderDetail) => {
                    let invoiceDetail = {
                        name: orderDetail.productTitle,
                        quantity: orderDetail.quantity,
                        unitPrice: orderDetail.unitPrice,
                        isActive: true,
                    };
                    this.invoiceDetails.push(invoiceDetail);
                });
            }

            this.invoice.mtlTracking = this.shipment.mtlTracking;
            this.updateTotalAmount();
        } else {
            this.currencyId = this.getIdByEnum(CurrencyType.UsDollar);
        }
        this.$refs["form"].validate();
    },
    props: {
        shipment: {
            type: Object,
            default: () => {
                return {};
            },
        },
        type: {
            type: Number,
            default: null,
        },
        order: {
            type: Object,
            default: () => {
                return {};
            },
        },
        isGenerating: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            deleteDialog: false,
            customerDialog: false,
            paymentDialog: false,
            cashDialog: false,
            digitalDialog: false,
            originalInvoice: {},
            isUpdating: false,
            isPaymentLoading: false,
            isLoading: false,
            isDeleteLoading: false,
            invoiceDetails: [],
            deleteOptions: {
                btnText: "Yes",
                message: "This action is not revertable!",
                title: "Are you sure you want to delete this invoice?",
            },
            paymentStatusId: null,
            invoice: {
                typeId: null,
                customerId: null,
                associatedId: null,
                description: "",
                totalAmount: 0,
                payment: {},
                paymentStatusId: null,
                currencyId: null,
            },
            currencyId: null,
            totalAmount: 0,
            formValid: false,
            paymentFormValid: false,
            payment: {},
            dropdownRules: [
                (v) => {
                    if (v) return true;
                    return "Required";
                },
            ],
        };
    },
    computed: {
        currencySymbol() {
            let us = this.getIdByEnum(CurrencyType.UsDollar);
            let viet = this.getIdByEnum(CurrencyType.VietDong);
            if (this.currencyId == us) {
                return "$";
            } else if (this.currencyId == viet) {
                return "₫";
            }
            return "";
        },
        currencyTypes() {
            if (this.$store.getters["dropdownToOptions"]["currency"])
                return this.$store.getters["dropdownToOptions"]["currency"]
                    .options;
            return [];
        },
        customers() {
            return this.$store.getters["customers"];
        },
        shipments() {
            return this.$store.getters["shipments"];
        },
        items() {
            if (this.invoice.typeId == this.getIdByEnum(InvoiceType.Shipment))
                return this.shipments;
            else if (this.invoice.typeId == this.getIdByEnum(InvoiceType.Order))
                return this.orders;

            return [];
        },
        Paid() {
            return this.getIdByEnum(PaymentStatus.Paid);
        },
        general() {
            return this.getIdByEnum(InvoiceType.General);
        },
        orders() {
            return this.$store.getters["orders"];
        },
        invoiceTypes() {
            if (
                this.$store.getters["dropdownToOptions"] &&
                this.$store.getters["dropdownToOptions"]["invoiceType"]
            )
                return this.$store.getters["dropdownToOptions"]["invoiceType"]
                    .options;
            return [];
        },
        paymentStatuses() {
            if (
                this.$store.getters["dropdownToOptions"] &&
                this.$store.getters["dropdownToOptions"]["paymentStatus"]
            )
                return this.$store.getters["dropdownToOptions"]["paymentStatus"]
                    .options;
            return [];
        },
        paymentTypes() {
            if (
                this.$store.getters["dropdownToOptions"] &&
                this.$store.getters["dropdownToOptions"]["paymentType"]
            )
                return this.$store.getters["dropdownToOptions"]["paymentType"]
                    .options;
            return [];
        },
        paymentDate() {
            if (this.invoice.payment && this.invoice.payment.dateCreated)
                return this.convertToDate(this.invoice.payment.dateCreated);
            return "";
        },
        Shipment() {
            return this.getIdByEnum(InvoiceType.Shipment);
        },
    },
    methods: {
        addInvoiceDetail() {
            this.invoiceDetails.push({
                isActive: true,
                name: "",
                unitPrice: 0,
                quantity: 0,
                lineTotal: 0,
            });
        },
        addInvoice() {
            if (!this.$refs.form.validate()) return;
            this.isLoading = true;
            this.invoice.currencyId = this.currencyId;
            this.invoice.invoiceDetails = this.invoiceDetails;
            backendService
                .addInvoice(this.invoice)
                .then((response) => {
                    this.timer(1000).then(() => {
                        this.invoice = response.data;
                        this.invoiceDetails = this.invoice.invoiceDetails;
                        this.isLoading = false;
                        this.isUpdating = true;
                        this.$router.push({
                            name: "InvoiceUpdate",
                            params: { id: this.invoice.id },
                        });
                        this.successSnackbar(
                            "Successfully added a new invoice"
                        );
                    });
                })
                .catch((err) => {
                    console.log(err);
                    let message = err.response.data;
                    this.timer(1000).then(() => {
                        if (
                            typeof message == "string" &&
                            message.includes("Duplicate")
                        ) {
                            this.errorSnackbar(
                                "Repeated tracking # under invoice list"
                            );
                        } else {
                            this.errorSnackbar("Ops! Something is not right");
                        }
                        this.isLoading = false;
                    });
                });
        },
        deleteInvoice() {
            this.isDeleteLoading = true;
            backendService
                .deleteInvoiceById(this.invoice.id)
                .then(() => {
                    setTimeout(() => {
                        this.isDeleteLoading = false;
                        this.$router.push({ name: "InvoiceList" });
                        eventBus.$emit(
                            "setSnackbar",
                            "Successfully delete the invoice",
                            "success",
                            true
                        );
                    }, 1000);
                })
                .catch(() => {
                    this.isDeleteLoading = false;
                    eventBus.$emit(
                        "setSnackbar",
                        "Ops! Something is not right!",
                        "error",
                        true
                    );
                }, 1000);
        },
        deleteInvoiceDetail(idx) {
            this.invoiceDetails.splice(idx, 1);
        },
        getCustomerName(item) {
            return `${item.id} - ${item.name} - ${item.phone}`;
        },
        getUpdateForm() {
            this.isUpdating = true;
            this.getInvoiceById(this.$route.params.id);
        },
        getInvoiceById(id) {
            backendService.getInvoiceById(id).then((response) => {
                this.invoice = response.data;
                this.currencyId = this.invoice.currencyId;
                this.invoiceDetails = this.invoice.invoiceDetails;
                this.originalInvoice = JSON.stringify(this.invoice);
                this.paymentStatusId = this.invoice.paymentStatusId;
                if (this.invoice.payment != null)
                    this.payment = this.invoice.payment;
            });
        },
        openPaymentDialog(val) {
            if (val == this.getIdByEnum(PaymentType.Cash))
                this.cashDialog = !this.cashDialog;
            else if (val == this.getIdByEnum(PaymentType.Aba))
                this.digitalDialog = !this.digitalDialog;
        },
        undoRemoveInvoiceDetail(idx) {
            this.invoiceDetails[idx].isActive = true;
            this.updateTotalAmount();
        },
        removeInvoiceDetail(idx) {
            this.invoiceDetails[idx].isActive = false;
            this.updateTotalAmount();
        },
        updateInvoice() {
            this.isLoading = true;
            this.invoice.currencyId = this.currencyId;
            this.invoice.invoiceDetails = this.invoiceDetails;
            backendService
                .updateInvoice(this.invoice)
                .then((response) => {
                    this.timer(1000).then(() => {
                        this.invoice = response.data;
                        this.invoiceDetails = this.invoice.invoiceDetails;
                        this.originalInvoice = JSON.stringify(this.invoice);
                        this.isLoading = false;
                        this.successSnackbar("Successfully updated invoice");
                    });
                })
                .catch((err) => {
                    let message = err.response.data;
                    this.timer(1000).then(() => {
                        if (
                            typeof message == "string" &&
                            message.includes("Duplicate")
                        ) {
                            this.errorSnackbar(
                                "Repeated tracking # under invoice list"
                            );
                        } else {
                            this.errorSnackbar("Ops! Something is not right");
                        }
                        this.isLoading = false;
                    });
                });
        },
        updateTotalAmount() {
            this.invoice.totalAmount = this.invoiceDetails.reduce((ps, a) => {
                if (a.isActive)
                    return new Decimal(ps).plus(
                        new Decimal(a.unitPrice).times(new Decimal(a.quantity))
                    );
                return ps;
            }, 0);
        },
        submitDigitalPayment() {
            let paymentInfo = {
                typeId: this.getIdByEnum(PaymentType.Aba),
            };
            this.submitPayment(paymentInfo);
        },
        submitPayment() {
            this.isPaymentLoading = true;
            this.payment.amount = this.invoice.totalAmount;
            this.payment.tender = 0;
            this.payment.cashChange = 0;
            this.payment.invoiceId = this.invoice.id;
            backendService
                .submitPayment(this.payment)
                .then((response) => {
                    setTimeout(() => {
                        this.isPaymentLoading = false;
                        this.invoice.payment = response.data;
                        this.invoice.paymentStatusId = this.Paid;
                        this.paymentStatusId = this.Paid;
                        eventBus.$emit(
                            "setSnackbar",
                            "Successfully submitted a new payment",
                            "success",
                            true
                        );
                    }, 1000);
                })
                .catch(() => {
                    setTimeout(() => {
                        this.isPaymentLoading = false;
                        eventBus.$emit(
                            "setSnackbar",
                            "Ops! something is not right",
                            "error",
                            true
                        );
                    }, 1000);
                });
        },
    },
    watch: {
        "$route.name": {
            handler: function (name) {
                if (name == "NewInvoice") {
                    this.isLoading = false;
                    this.isUpdating = false;
                    this.totalAmount = 0;
                    this.invoiceDetails = [];
                    this.paymentStatusId = null;
                    this.invoice = {
                        typeId: null,
                        customerId: null,
                        associatedId: null,
                        description: "",
                        totalAmount: 0,
                        payment: {},
                        paymentStatusId: null,
                    };
                }
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>
