<template>
    <v-container fluid class="pa-0">
        <v-data-table
            :headers="headers"
            :items="filteredShipments"
            :items-per-page="15"
            class="elevation-1">
            <template v-slot:header>
                <tr>
                    <th v-for="(header, idx) in headers" :key="`header-${idx}`">
                        <v-text-field
                            dense
                            class="ma-2"
                            rounded
                            v-model="search[header.value]"
                            placeholder="filter"
                            solo-inverted
                            hide-details
                            flat></v-text-field>
                    </th>
                </tr>
            </template>
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        <a
                            @click="
                                $router.push({
                                    name: 'ShipmentUpdate',
                                    params: { id: item.id },
                                })
                            "
                            >{{ item.id }}</a
                        >
                    </td>
                    <td>{{ item.mtlTracking }}</td>

                    <td>{{ getCustomerName(item.senderId) }}</td>
                    <td>{{ getCustomerName(item.receiverId) }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.totalBox }}/{{ item.totalWeight }}</td>
                    <td>{{ convertToDate(item.shipmentDate) }}</td>
                    <td>{{ getOptionName(item.shipmentStatusId) }}</td>
                    <td>
                        <a
                            v-if="item.invoiceId"
                            @click="
                                $router.push({
                                    name: 'InvoiceUpdate',
                                    params: { id: item.invoiceId },
                                })
                            "
                            >{{ item.invoiceId }}</a
                        >
                    </td>
                </tr>
            </template></v-data-table
        >
    </v-container>
</template>
<script>
import generalMixin from "@/js/general-mixin";
export default {
    name: "ShipmentList",
    mixins: [generalMixin],
    mounted: function () {},
    data() {
        return {
            headers: [
                {
                    text: "Id",
                    align: "start",
                    value: "id",
                },
                {
                    text: "Tracking #",
                    align: "start",
                    value: "mtlTracking",
                },

                { text: "Sender", value: "senderId" },
                { text: "Receiver", value: "receiverId" },
                { text: "Description", value: "description" },
                { text: "Box/Weight", value: "boxWeight" },
                { text: "Shipment Date", value: "shipmentDate" },
                {
                    text: "Shipment Status",
                    value: "shipmentStatusId",
                },
                {
                    text: "Invoice Id",
                    value: "invoiceId",
                },
            ],
            search: {},
        };
    },
    computed: {
        customers() {
            return this.$store.getters["customers"];
        },
        shipmentGroups() {
            return this.$store.getters["shipmentGroups"];
        },
        shipments: {
            get() {
                return this.$store.getters["shipments"];
            },
            set(val) {
                this.$store.commit("setShipments", val);
            },
        },
        filteredShipments() {
            let shipments = this.shipments;
            for (let key in this.search) {
                let searchStr = this.search[key].trim().toLowerCase();
                let header = this.headers.find((header) => header.value == key);
                if (searchStr && searchStr.length) {
                    shipments = shipments.filter((e) => {
                        let str = this.getDataText(header, e);
                        return str
                            .toString()
                            .trim()
                            .toLowerCase()
                            .includes(this.search[key].toLowerCase());
                    });
                }
            }
            return shipments;
        },
    },
    methods: {
        getDataText(header, shipment) {
            if (header.value == "id") {
                return shipment.id.toString();
            } else if (header.value == "mtlTracking") {
                return shipment.mtlTracking;
            } else if (header.value == "invoiceId") {
                return shipment.invoiceId.toString();
            } else if (header.value == "senderId") {
                return this.getCustomerName(shipment.senderId);
            } else if (header.value == "receiverId") {
                return this.getCustomerName(shipment.receiverId);
            } else if (header.value == "dateCreated") {
                return this.convertToDate(shipment.dateCreated);
            } else if (header.value == "shipmentDate") {
                return this.convertToDate(shipment.shipmentDate);
            } else if (header.value == "description") {
                return shipment.description;
            } else if (header.value == "shipmentStatusId") {
                return this.getOptionName(shipment.shipmentStatusId);
            } else if (header.value == "boxWeight") {
                return `${shipment.totalBox}/${shipment.totalWeight}`;
            } else {
                return "";
            }
        },
    },
};
</script>
<style>
.payment-paid {
    background-color: lightgreen;
}
</style>
