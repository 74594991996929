<template>
    <v-container class="pa-0">
        <v-container fluid v-if="!isMobile" class="w-75">
            <v-container
                class="roboto-regular white--text text--darken-3 text-center"
                style="font-size: 50px; font-weight: bolder; opacity: 0.9">
                MTL Cargo - Chuyen Hang Ve VN
            </v-container>
            <v-container class="d-flex rounded-xl grey lighten-3 mb-2">
                <v-container
                    fluid
                    style="width: 900px"
                    class="white rounded-xl d-flex mr-2"
                    ><v-container class="pa-0">
                        <v-container class="ml-5"
                            ><h1>BẢNG GIÁ</h1>
                        </v-container>
                        <v-container class="ml-5">
                            <v-container>
                                <i
                                    class="fa-solid fa-map-pin red--text text--accent-2"
                                    style="font-size: 50px"></i>
                                SÀI GÒN: $2.75/lbs
                            </v-container>
                            <v-container>
                                <i
                                    class="fa-solid fa-map-pin red--text text--accent-2"
                                    style="font-size: 50px"></i>
                                TỈNH: $3.5/lbs</v-container
                            >
                        </v-container>
                    </v-container>
                    <img
                        class="pa-0 my-auto"
                        src="@/assets/background-1.jpg"
                        style="width: 400px; height: 250px" />
                </v-container>
                <v-container
                    fluid
                    style="width: 500px; height: 300px"
                    class="white rounded-xl">
                    <v-container>
                        <v-text-field
                            class="mb-0"
                            background-color="white"
                            label="Tracking Number"
                            densed
                            rounded
                            outlined
                            filled
                            v-model="tracking"
                            v-on:keyup.enter="
                                track = true;
                                getShipmentStatus();
                            ">
                        </v-text-field>
                        <v-btn
                            block
                            color="warning"
                            rounded
                            :loading="isLoading"
                            @click="
                                track = true;
                                getShipmentStatus();
                            "
                            ><i class="fa-solid fa-magnifying-glass mr-2"></i
                            >Track</v-btn
                        >
                    </v-container>
                    <v-expand-transition>
                        <v-container
                            v-if="track == true"
                            class="grey lighten-3 rounded-xl mb-5">
                            <v-container v-if="track == true">
                                <v-timeline
                                    dense
                                    align-top
                                    v-if="statuses.length > 0">
                                    <v-timeline-item
                                        small
                                        v-for="(item, idx) in statuses"
                                        :key="idx"
                                        color="deep-orange accent-2"
                                        ><v-container
                                            class="d-flex justify-space-around caption pa-0"
                                            ><div>{{ item.statusName }}</div>

                                            <div class="caption">
                                                {{
                                                    convertToUsDate(
                                                        item.dateCreated
                                                    )
                                                }}
                                            </div></v-container
                                        >
                                    </v-timeline-item>
                                </v-timeline>
                                <v-container
                                    class="text-center grey--text"
                                    v-if="
                                        statuses.length == 0 &&
                                        track &&
                                        !isLoading
                                    "
                                    ><h1>
                                        <i class="fa-regular fa-face-frown"></i>
                                        No Update!
                                    </h1></v-container
                                >
                            </v-container>
                        </v-container></v-expand-transition
                    >
                </v-container>
            </v-container>

            <v-container class="grey lighten-3 rounded-xl mb-5">
                <v-container> <h1>Our Services</h1></v-container>
                <v-container class="d-flex justify-space-around">
                    <v-card elevation="3" width="300" class="rounded-xl">
                        <v-card-text class="text-center"
                            ><h1>Custom Orders</h1></v-card-text
                        >
                        <v-card-text
                            ><v-img
                                v-if="$route.name == 'HomePage'"
                                src="@/assets/service-1.jpg"></v-img
                        ></v-card-text>
                    </v-card>
                    <v-card
                        elevation="3"
                        outlined
                        width="300"
                        class="rounded-xl">
                        <v-card-text class="text-center"
                            ><h1>Air Freight</h1></v-card-text
                        >
                        <v-card-text
                            ><v-img
                                v-if="$route.name == 'HomePage'"
                                src="@/assets/service-3.jpg"></v-img
                        ></v-card-text>
                    </v-card>
                    <v-card
                        elevation="3"
                        outlined
                        width="300"
                        class="rounded-xl">
                        <v-card-text class="text-center"
                            ><h1>Sea Freight</h1></v-card-text
                        >

                        <v-card-text
                            ><v-img
                                v-if="$route.name == 'HomePage'"
                                src="@/assets/service-2.jpg"></v-img
                        ></v-card-text>
                    </v-card>
                </v-container>
            </v-container>
            <v-container class="grey lighten-3 rounded-xl mb-5">
                <v-container class="d-flex justify-space-between align-center"
                    ><h1>Our Products</h1>
                    <v-btn
                        rounded
                        depressed
                        color="primary"
                        @click="
                            $router.push({ name: 'SearchPage' }).catch(() => {})
                        "
                        >See More</v-btn
                    ></v-container
                >
                <vue-marquee-slider
                    id="marquee-slider"
                    :speed="speed"
                    :width="200"
                    :space="20"
                    v-if="products.length > 0">
                    <product-card
                        v-for="product in products"
                        :key="`product-${product.id}`"
                        :product="product"
                        :clickable="false"
                        :show-price="false"></product-card
                ></vue-marquee-slider>
            </v-container>
            <v-container class="grey lighten-3 rounded-xl mb-5">
                <v-container> <h1>Contact Us</h1></v-container>
                <v-container fluid class="w-75 py-0"
                    ><v-text-field
                        :rules="nameRules"
                        dense
                        outlined
                        rounded
                        label="Fullname"
                        v-model="contactUs.name"></v-text-field
                ></v-container>
                <v-container fluid class="w-75 d-flex py-0"
                    ><v-text-field
                        :rules="phoneRules"
                        class="mr-2"
                        dense
                        outlined
                        rounded
                        label="Phone"
                        v-model="contactUs.phone"></v-text-field
                    ><v-text-field
                        :rules="emailRules"
                        dense
                        outlined
                        rounded
                        label="Email"
                        v-model="contactUs.email"></v-text-field
                ></v-container>
                <v-container fluid class="w-75 py-0"
                    ><v-textarea
                        :rules="textRules"
                        rounded
                        outlined
                        dense
                        label="Message"
                        v-model="contactUs.message"></v-textarea
                ></v-container>
                <v-container fluid class="w-75"
                    ><v-btn
                        v-if="!isSuccessful"
                        block
                        rounded
                        depressed
                        color="primary"
                        :disabled="!enableSubmit"
                        :loading="isLoading"
                        @click="addContactUs"
                        ><i class="fa-regular fa-paper-plane mr-2"></i
                        >Submit</v-btn
                    >
                    <v-btn
                        v-else
                        block
                        rounded
                        depressed
                        color="primary"
                        disabled="true"
                        ><i class="fa-regular fa-paper-plane mr-2"></i
                        >Submitted</v-btn
                    ></v-container
                >
            </v-container>
        </v-container>
        <v-container v-else>
            <v-container class="rounded-xl grey lighten-3 mb-2">
                <v-container
                    v-if="!isLoggedIn"
                    class="roboto-regular mb-2 rounded-xl white text-center grey--text text--darken-2"
                    style="font-size: 18px; font-weight: bolder">
                    MTL Cargo - Chuyen Hang Ve VN
                </v-container>
                <v-container
                    fluid
                    style="width: 100%"
                    class="white rounded-xl mb-2">
                    <v-container>
                        <v-text-field
                            class="mb-0"
                            background-color="white"
                            label="Tracking Number"
                            densed
                            rounded
                            outlined
                            filled
                            v-model="tracking"
                            v-on:keyup.enter="
                                track = true;
                                getShipmentStatus();
                            ">
                        </v-text-field>
                        <v-btn
                            block
                            color="warning"
                            rounded
                            :loading="isLoading"
                            @click="
                                track = true;
                                getShipmentStatus();
                            "
                            ><i class="fa-solid fa-magnifying-glass mr-2"></i
                            >Track</v-btn
                        >
                    </v-container>
                    <v-expand-transition>
                        <v-container
                            v-if="track == true"
                            class="grey lighten-3 rounded-xl mb-5">
                            <v-container v-if="track == true">
                                <v-timeline
                                    dense
                                    align-top
                                    v-if="statuses.length > 0">
                                    <v-timeline-item
                                        small
                                        v-for="(item, idx) in statuses"
                                        :key="idx"
                                        color="deep-orange accent-2"
                                        ><v-container
                                            class="d-flex justify-space-around caption pa-0"
                                            ><div>{{ item.statusName }}</div>

                                            <div class="caption">
                                                {{
                                                    convertToUsDate(
                                                        item.dateCreated
                                                    )
                                                }}
                                            </div></v-container
                                        >
                                    </v-timeline-item>
                                </v-timeline>
                                <v-container
                                    class="text-center grey--text"
                                    v-if="
                                        statuses.length == 0 &&
                                        track &&
                                        !isLoading
                                    "
                                    ><h1>
                                        <i class="fa-regular fa-face-frown"></i>
                                        No Update!
                                    </h1></v-container
                                >
                            </v-container>
                        </v-container></v-expand-transition
                    >
                </v-container>
                <v-container
                    fluid
                    style="width: 900px"
                    class="white rounded-xl mr-2"
                    ><v-container class="pa-0">
                        <v-container class="ml-5"
                            ><h3>BẢNG GIÁ</h3>
                        </v-container>
                        <v-container class="ml-5">
                            <v-container>
                                <i
                                    class="fa-solid fa-map-pin red--text text--accent-2"
                                    style="font-size: 50px"></i>
                                SÀI GÒN: $2.75/lbs
                            </v-container>
                            <v-container>
                                <i
                                    class="fa-solid fa-map-pin red--text text--accent-2"
                                    style="font-size: 50px"></i>
                                TỈNH: $3.5/lbs</v-container
                            >
                        </v-container>
                    </v-container>
                    <img
                        class="pa-0 my-auto"
                        src="@/assets/background-1.jpg"
                        style="width: 100%; height: 50%" />
                </v-container>
            </v-container>

            <v-container class="grey lighten-3 rounded-xl mb-5">
                <v-container> <h3>Our Services</h3></v-container>
                <v-container>
                    <v-card elevation="1" class="rounded-xl mb-2">
                        <v-card-text class="text-center"
                            ><h2>Custom Orders</h2></v-card-text
                        >
                        <v-card-text class="text-center"
                            ><v-img
                                class="mx-auto"
                                width="30%"
                                v-if="$route.name == 'HomePage'"
                                src="@/assets/service-1.jpg"></v-img
                        ></v-card-text>
                    </v-card>
                    <v-card elevation="1" outlined class="rounded-xl mb-2">
                        <v-card-text class="text-center"
                            ><h2>Air Freight</h2></v-card-text
                        >
                        <v-card-text
                            ><v-img
                                class="mx-auto"
                                width="30%"
                                v-if="$route.name == 'HomePage'"
                                src="@/assets/service-3.jpg"></v-img
                        ></v-card-text>
                    </v-card>
                    <v-card elevation="3" outlined class="rounded-xl">
                        <v-card-text class="text-center"
                            ><h2>Sea Freight</h2></v-card-text
                        >

                        <v-card-text
                            ><v-img
                                class="mx-auto"
                                width="30%"
                                v-if="$route.name == 'HomePage'"
                                src="@/assets/service-2.jpg"></v-img
                        ></v-card-text>
                    </v-card>
                </v-container>
            </v-container>
            <v-container class="grey lighten-3 rounded-xl mb-5">
                <v-container class="d-flex justify-space-between align-center"
                    ><h1>Our Products</h1>
                    <v-btn
                        rounded
                        depressed
                        color="primary"
                        @click="
                            $router.push({ name: 'SearchPage' }).catch(() => {})
                        "
                        >See More</v-btn
                    ></v-container
                >
                <vue-marquee-slider
                    id="marquee-slider"
                    :speed="speed"
                    :width="200"
                    :space="20"
                    v-if="products.length > 0">
                    <product-card
                        v-for="product in products"
                        :key="`product-${product.id}`"
                        :product="product"
                        :clickable="false"
                        :show-price="false"></product-card
                ></vue-marquee-slider>
            </v-container>

            <v-container class="grey lighten-3 rounded-xl mb-5">
                <v-container> <h3>Contact Us</h3></v-container>
                <v-container class="py-0"
                    ><v-text-field
                        :rules="nameRules"
                        dense
                        outlined
                        rounded
                        label="Fullname"
                        v-model="contactUs.name"></v-text-field
                ></v-container>
                <v-container class="d-flex py-0"
                    ><v-text-field
                        :rules="phoneRules"
                        class="mr-2"
                        dense
                        outlined
                        rounded
                        label="Phone"
                        v-model="contactUs.phone"></v-text-field
                    ><v-text-field
                        :rules="emailRules"
                        dense
                        outlined
                        rounded
                        label="Email"
                        v-model="contactUs.email"></v-text-field
                ></v-container>
                <v-container class="py-0"
                    ><v-textarea
                        :rules="textRules"
                        rounded
                        outlined
                        dense
                        label="Message"
                        v-model="contactUs.message"></v-textarea
                ></v-container>
                <v-container
                    ><v-btn
                        v-if="!isSuccessful"
                        block
                        rounded
                        depressed
                        color="primary"
                        :disabled="!enableSubmit"
                        :loading="isLoading"
                        @click="addContactUs"
                        ><i class="fa-regular fa-paper-plane mr-2"></i
                        >Submit</v-btn
                    >
                    <v-btn
                        v-else
                        block
                        rounded
                        depressed
                        color="primary"
                        disabled="true"
                        ><i class="fa-regular fa-paper-plane mr-2"></i
                        >Submitted</v-btn
                    ></v-container
                >
            </v-container>
        </v-container>
    </v-container>
</template>

<script>
// import CategoryList from "@/components/CategoryList.vue";
// import CarouselSlide from "@/components/CarouselSlide.vue";
// import ProductCardList from "@/components/ProductCardList.vue";
import ProductCard from "@/components/ProductCard.vue";
import formMixin from "@/js/form-mixin";
import eventBus from "@/js/event-bus";
import backendService from "@/services/backend-service";
export default {
    name: "HomePage",
    components: {
        // "carousel-slide": CarouselSlide,
        // "category-list": CategoryList,
        // "product-card-list": ProductCardList,
        "product-card": ProductCard,
    },
    mixins: [formMixin],
    mounted: function () {
        this.getProductsByStoreId(1, {});
    },
    data() {
        return {
            track: false,
            isLoading: false,
            isSuccessful: false,
            contactUs: { name: "", email: "", phone: "", message: "" },
            statuses: [],
            products: [],
            tracking: "",
        };
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters["isLoggedIn"];
        },
        isMobile() {
            return this.$store.getters["isMobile"];
        },
        background() {
            return require("@/assets/harbor.jpg");
        },
        enableSubmit() {
            return (
                this.contactUs.name &&
                this.contactUs.email &&
                this.contactUs.phone &&
                this.contactUs.message
            );
        },
        speed() {
            return (this.products.length * 20000) / 4;
        },
    },
    methods: {
        addContactUs() {
            this.isLoading = true;
            backendService
                .addContactUs(this.contactUs)
                .then(() => {
                    setTimeout(() => {
                        this.isLoading = false;
                        this.isSuccessful = true;
                        eventBus.$emit(
                            "setSnackbar",
                            "Successfully added your information",
                            "success",
                            true
                        );
                    }, 1000);
                })
                .catch(() => {
                    setTimeout(() => {
                        this.isLoading = false;
                        eventBus.$emit(
                            "setSnackbar",
                            "Ops! something is not right",
                            "error",
                            true
                        );
                    }, 1000);
                });
        },
        convertToUsDate(val) {
            return new Date(val).toLocaleDateString("en-US");
        },
        getProductsByStoreId(storeId, filter) {
            backendService
                .getProductsByStoreId(storeId, filter)
                .then((response) => {
                    this.products = response.data;
                });
        },
        getShipmentStatus() {
            this.isLoading = true;

            backendService
                .getShipmentStatusByTracking(this.tracking)
                .then((response) => {
                    setTimeout(() => {
                        this.statuses = response.data;
                        this.isLoading = false;
                    }, 1000);
                })
                .catch(() => {
                    setTimeout(() => {
                        this.isLoading = false;
                    });
                });
        },
    },
};
</script>
