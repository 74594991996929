import axios from "axios";
let host = window.location.hostname;
let domain = `http://192.168.0.222:3000`;
if (host.toLowerCase().includes("jaekjay")) {
	domain = `https://mtlcargo.jaekjay.com`;
}
if (host.toLowerCase().includes("mtlcargo.net")) {
	domain = `https://mtlcargo.net`;
}
function getHeaders() {
	let headers = {};
	headers.authorization = localStorage.getItem("sessionId");
	return headers;
}
export default {
	addContactUs(contactUsInfo) {
		let url = domain +"/api/contactus/add";
		return axios.post(url,contactUsInfo);
	},
	addCustomer(customer) {
		let url = domain + "/api/customer/add";
		let headers = getHeaders();
		return axios.post(url,customer,{headers:headers});
	},
	addDeliveryAddress(deliveryAddressInfo) {
		let url = domain + "/api/delivery/address/add";
		let headers = getHeaders();
		return axios.post(url, deliveryAddressInfo, { headers: headers });
	},
	addInvoice(invoice) {
		let url = domain +"/api/invoice/add";
		let headers = getHeaders();
		return axios.post(url,invoice, {headers:headers});
	},
	addProductPictureInfos(pictureInfos) {
		let url = domain + "/api/file/add/product/picture/infos";
		let headers = getHeaders();
		return axios.post(url, pictureInfos, { headers: headers });
	},
	addProduct(product) {
		let url = domain + "/api/product/add";
		let headers = getHeaders();
		return axios.post(url, product, { headers: headers });
	},
	addPayment(payment) {
		let url = domain + "/api/payment/add";
		let headers = getHeaders();
		return axios.post(url, payment, { headers: headers });
	},
	addStock(stockInfo) {
		let url = domain + "/api/stock/add";
		let headers = getHeaders();
		return axios.post(url, stockInfo, { headers: headers });
	},
	addShipmentGroup(shipmentGroupInfo) {
		let url = domain +"/api/shipment/add/group";
		let headers = getHeaders();
		return axios.post(url,shipmentGroupInfo,{headers:headers});
	},
	addShipment(shipmentInfo) {
		let url = domain +"/api/shipment/add";
		let headers = getHeaders();
		return axios.post(url, shipmentInfo, {headers:headers});
	},
	deleteProductPictureById(id) {
		let url = domain + `/api/file/delete/product/picture/by/id/${id}`;
		let headers = getHeaders();
		return axios.get(url, { headers: headers });
	},
	deleteOrderById(id) {
		let url = domain + `/api/order/delete/by/id/${id}`;
		let headers = getHeaders();
		return axios.get(url,{headers:headers});
	},
	deleteDeliveryInfoById(id) {
		let url = domain + `/api/delivery/address/delete/by/id/${id}`;
		let headers = getHeaders();
		return axios.get(url, { headers: headers });
	},
	deleteStockById(id) {
		let url = domain +`/api/stock/delete/by/id/${id}`;
		let headers = getHeaders();
		return axios.get(url,{headers:headers});
	},
	generateProductReport(filters) {
		let url = domain + `/api/product/generate`;
		let headers = getHeaders();
		return axios.post(url,filters,{headers:headers})
	},
	generateReportByGroupId(groupId) {
		let url = domain +`/api/shipment/generate/report/by/group/id/${groupId}`;
		let headers = getHeaders();
		return axios.get(url,{headers:headers});
	},
	getCustomerById(id) {
		let url = domain + `/api/customer/get/by/id/${id}`;
		let headers = getHeaders();
		return axios.get(url,{headers:headers});
	},
	getCustomers(){
		let url = domain + `/api/customer/get`;
		let headers = getHeaders();
		return axios.get(url,{headers:headers});
	},
	getInvoiceById(id) {
		let url = domain + `/api/invoice/get/by/id/${id}`;
		let headers = getHeaders();
		return axios.get(url,{headers:headers});
	},
	getProductById(id) {
		let url = domain + `/api/product/get/by/id/${id}`;
		let headers = getHeaders();
		return axios.get(url, { headers: headers });
	},
	getProductsByStoreId(storeId, filter) {
		let url = domain + `/api/product/get/by/store/id/${storeId}`;
		let headers = getHeaders();
		return axios.post(url, filter, { headers: headers });
	},
	getProducts(filter) {
		let url = domain + `/api/product/get`;
		let headers = getHeaders();
		return axios.post(url, filter, { headers: headers });
	},
	getShipments() {
		let url = domain + `/api/shipment/get`;
		let headers = getHeaders();
		return axios.get(url,{headers:headers});
	},
	getShipmentGroups() {
		let url = domain + `/api/shipment/get/groups`;
		let headers = getHeaders();
		return axios.get(url,{headers:headers});
	},
	getShipmentGroupById(id) {
		let url = domain +`/api/shipment/get/group/by/id/${id}`;
		let headers = getHeaders();
		return axios.get(url,{headers:headers});
	},
	getShipmentById(id) {
		let url = domain + `/api/shipment/get/by/id/${id}`;
		let headers = getHeaders();
		return axios.get(url,{headers:headers});
	},
	getShipmentByTracking(tracking) {
		let url = domain + `/api/shipment/get/by/tracking/${tracking}`;
		let headers = getHeaders();
		return axios.get(url,{headers:headers});
	},
	updateCustomer(customer) {
		let url = domain + "/api/customer/update";
		let headers = getHeaders();
		return axios.post(url,customer,{headers:headers});
	},
	updateShipment(shipment) {
		let url = domain + "/api/shipment/update";
		let headers = getHeaders();
		return axios.post(url, shipment,{headers:headers});
	},
	updateShipmentGroup(shipmentGroup) {
		console.log(shipmentGroup)
		let url = domain +"/api/shipment/update/group";
		let headers = getHeaders();
		return axios.post(url,shipmentGroup,{headers:headers});
	},
	uploadProductPictures(formData) {
		let url = domain + "/api/file/upload/product/pictures";
		let headers = getHeaders();
		headers["Content-Type"] = "multipart/form-data";
		return axios.post(url, formData, {
			headers: headers,
		});
	},
	addDropdownOption(option) {
		let url = domain + "/api/dropdown/add/option";
		let headers = getHeaders();
		return axios.post(url, option, { headers: headers });
	},
	addOrder(wrappedOrder) {
		let url = domain + "/api/order/add";
		let headers = getHeaders();
		return axios.post(url, wrappedOrder, { headers: headers });
	},
	deleteProductById(id) {
		let url = domain + `/api/product/delete/by/id/${id}`;
		let headers = getHeaders();
		return axios.get(url,{headers:headers});
	},
	deleteInvoiceById(id) {
		let url = domain + `/api/invoice/delete/by/id/${id}`;
		let headers = getHeaders();
		return axios.get(url,{headers:headers});
	},
	deleteShipmentById(id) {
		let url = domain + `/api/shipment/delete/by/id/${id}`;
		let headers = getHeaders();
		return axios.get(url,{headers:headers});
	},
	getDeliveryAddressesByUserId(userId) {
		let url = domain + `/api/delivery/address/get/by/user/id/${userId}`;
		let headers = getHeaders();
		return axios.get(url, { headers: headers });
	},
	getDropdownToOptions() {
		let url = domain + `/api/dropdown/get/to/options`;
		let headers = getHeaders();
		return axios.get(url, { headers: headers });
	},

	getProductPictureUrlById(pictureId) {
		if (!pictureId || pictureId == 0) return "";
		let url = domain + `/product/picture/${pictureId}`;
		return url;
	},
	getProductPicturesByProductId(productId) {
		let url =
			domain +
			`/api/file/get/product/pictures/by/product/id/${productId}`;
		let headers = getHeaders();
		return axios.get(url, { headers: headers });
	},
	getInvoices() {
		let url = domain + `/api/invoice/get`;
		let headers = getHeaders();
		return axios.get(url,{headers:headers});
	},
	// getTodayAdsLst() {
	// 	// let url = domain + `/api/ads/get/today`;
	// 	// let headers = getHeaders();
	// 	// return axios.get(url, { headers: headers });
	// 	let promise = new Promise((resolve, reject) => {
	// 		resolve({ code: 200, data: [{}] });
	// 	});
	// 	return promise;
	// },
	getProductsByCategoryId(categoryId) {
		let url = domain + `/api/product/get/by/category/id/${categoryId}`;
		let headers = getHeaders();
		return axios.get(url, { headers: headers });
	},
	getOrdersByStatus(status) {
		let url = domain + `/Get/Orders/${status}`;
		return axios.get(url);
	},
	getMinimalOrders() {
		let url = domain + `/api/order/get/minimal`;
		let headers = getHeaders();
		return axios.get(url,{headers:headers});
	},
	getOrders() {
		let url = domain + `/api/order/get/`;
		let headers = getHeaders();
		return axios.get(url, { headers: headers });
	},
	getOrderById(id) {
		let url = domain + `/api/order/get/by/id/${id}`;
		let headers = getHeaders();
		return axios.get(url, { headers: headers });
	},
	getStocksByProductId(productId) {
		let url = domain + `/api/stock/get/by/product/id/${productId}`;
		let headers = getHeaders();
		return axios.get(url, { headers: headers });
	},
	getOrdersByProductId(productId) {
		let url = domain + `/api/order/get/by/product/id/${productId}`;
		let headers = getHeaders();
		return axios.get(url, { headers: headers });
	},
	getStoreById(id) {
		let url = domain + `/api/store/get/by/id/${id}`;
		let headers = getHeaders();
		return axios.get(url, { headers: headers });
	},
	getStoresByUserId(userId) {
		let url = domain + `/api/store/get/by/user/id/${userId}`;
		let headers = getHeaders();
		return axios.get(url, { headers: headers });
	},
	getContactUsLst() {
		let url = domain + `/api/contactus/get/`;
		let headers = getHeaders();
		return axios.get(url,{headers:headers});
	},
	getShipmentStatusByTracking(tracking) {
		let url = domain + `/api/shipment/get/status/by/tracking/${tracking}`;
		return axios.get(url);
	},
	updateShipmentStatusByGroupId(info){
		let url = domain + `/api/shipment/update/status/by/group/id`;
		let headers = getHeaders();
		return axios.post(url,info,{headers:headers});
	},
	login(loginInfo) {
		let url = domain + `/api/user/login`;
		return axios.post(url, loginInfo);
	},
	logout() {
		let url = domain + `/api/user/logout`;
		let headers = getHeaders();
		return axios.get(url, { headers: headers });
	},
	registerStore(store) {
		let url = domain + "/api/store/register";
		let headers = getHeaders();
		return axios.post(url, store, { headers: headers });
	},
	registerUser(user) {
		let url = domain + "/api/user/register";
		return axios.post(url, user);
	},
	submitPayment(paymentInfo) {
		let url = domain + "/api/payment/add";
		let headers = getHeaders();
		return axios.post(url,paymentInfo,{headers:headers});
	},
	unholdOrder(id,warehouseId) {
		let url = domain + `/api/order/unhold?id=${id}&warehouseId=${warehouseId}`;
		let headers = getHeaders();
		return axios.post(url,{},{headers:headers})
	},
	updateInvoice(invoice) {
		let url = domain + "/api/invoice/update";
		let headers = getHeaders();
		return axios.post(url,invoice, {headers:headers});
	},
	updateProduct(product) {
		let url = domain + "/api/product/update";
		let headers = getHeaders();
		return axios.post(url, product, { headers: headers });
	},
	updateOrderStatus(id, order) {
		let url = domain + `/Update/Order/Status/${id}`;
		return axios.post(url, order);
	},
	updateStock(stock) {
		let url = domain +`/api/stock/update`;
		let headers =getHeaders();
		return axios.post(url,stock,{headers:headers});
	},
	verifySession() {
		let url = domain + `/api/user/verify/session`;
		let headers = getHeaders();
		return axios.get(url, { headers: headers });
	},
	verifyAccount(id) {
		let url = domain + `/verify/account/${id}`;
		return axios.get(url);
	},
};
