<template>
    <v-container fluid class="pa-0">
        <v-data-table
            :headers="headers"
            :items="filteredInvoices"
            :items-per-page="15"
            class="elevation-1">
            <template v-slot:header>
                <tr>
                    <th v-for="(header, idx) in headers" :key="`header-${idx}`">
                        <v-text-field
                            dense
                            class="ma-2"
                            rounded
                            v-model="search[header.value]"
                            placeholder="filter"
                            solo-inverted
                            hide-details
                            flat></v-text-field>
                    </th>
                </tr>
            </template>
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        <a
                            @click="
                                $router.push({
                                    name: 'InvoiceUpdate',
                                    params: { id: item.id },
                                })
                            "
                            >{{ formatInvoiceId(item) }}</a
                        >
                    </td>
                    <td>{{ item.mtlTracking }}</td>
                    <td>{{ getCustomerName(item.customerId) }}</td>
                    <td>{{ item.description }}</td>
                    <td>
                        {{ getCurrencySymbol(item.currencyId) }}
                        {{ convertToCleanNumber(item.totalAmount) }}
                    </td>
                    <td
                        :class="{
                            'green--text': checkIfPaid(item.paymentStatusId),
                            'red--text': !checkIfPaid(item.paymentStatusId),
                        }">
                        {{ getOptionName(item.paymentStatusId) }}
                    </td>
                    <td>{{ convertToDate(item.dateCreated) }}</td>
                </tr>
            </template></v-data-table
        >
    </v-container>
</template>
<script>
import generalMixin from "@/js/general-mixin";
import backendService from "@/services/backend-service";
import { CurrencyType } from "@/js/enum.js";
export default {
    name: "ShipmentList",
    mixins: [generalMixin],
    mounted: function () {
        this.getInvoices();
    },
    data() {
        return {
            headers: [
                {
                    text: "Id",
                    align: "start",
                    value: "id",
                },
                {
                    text: "Tracking #",
                    align: "start",
                    value: "mtlTracking",
                },
                { text: "Customer", value: "customerId" },
                { text: "Description", value: "description" },
                {
                    text: "Total Amount",
                    value: "totalAmount",
                },
                { text: "Payment", value: "paymentStatusId" },
                { text: "Date Created", value: "dateCreated" },
            ],
            invoices: [],
            search: {},
        };
    },
    computed: {
        filteredInvoices() {
            let invoices = this.invoices;
            for (let key in this.search) {
                let searchStr = this.search[key].trim().toLowerCase();
                let header = this.headers.find((header) => header.value == key);
                if (searchStr && searchStr.length) {
                    invoices = invoices.filter((e) => {
                        let str = this.getDataText(header, e);
                        return str
                            .toString()
                            .trim()
                            .toLowerCase()
                            .includes(this.search[key].toLowerCase());
                    });
                }
            }
            return invoices;
        },
        customers() {
            return this.$store.getters["customers"];
        },
        shipmentGroups() {
            return this.$store.getters["shipmentGroups"];
        },
        shipments: {
            get() {
                return this.$store.getters["shipments"];
            },
            set(val) {
                this.$store.commit("setShipments", val);
            },
        },
    },
    methods: {
        formatInvoiceId(invoice) {
            return `${this.getOptionName(
                invoice.typeId
            )[0].toUpperCase()}-${String(invoice.id).padStart(5, "0")}`;
        },
        getDataText(header, invoice) {
            if (header.value == "id") {
                return invoice.id.toString();
            } else if (header.value == "mtlTracking") {
                return invoice.mtlTracking ? invoice.mtlTracking : "";
            } else if (header.value == "customerId") {
                return this.getCustomerName(invoice.customerId);
            } else if (header.value == "description") {
                return invoice.description;
            } else if (header.value == "totalAmount") {
                return invoice.totalAmount.toString();
            } else if (header.value == "paymentStatusId") {
                return this.getOptionName(invoice.paymentStatusId);
            } else if (header.value == "dateCreated") {
                return this.convertToDate(invoice.dateCreated);
            } else {
                return "";
            }
        },
        getInvoices() {
            backendService.getInvoices().then((response) => {
                this.invoices = response.data;
            });
        },
        getCurrencySymbol(currencyId) {
            let us = this.getIdByEnum(CurrencyType.UsDollar);
            let viet = this.getIdByEnum(CurrencyType.VietDong);
            if (currencyId == us) {
                return "$";
            } else if (currencyId == viet) {
                return "₫";
            }
            return "";
        },
    },
};
</script>
<style>
.payment-paid {
    background-color: lightgreen;
}
</style>
