<template>
    <v-container fluid>
        <v-form ref="form" v-model="formValid" lazy-validation>
            <v-container class="white rounded-xl mb-3">
                <v-text-field
                    rounded
                    outlined
                    dense
                    label="Title"
                    v-model="value.title"
                    :rules="titleRules"
                    required></v-text-field>
            </v-container>
            <v-container
                fluid
                class="d-flex justify-space-between white rounded-xl mb-3">
                <v-container fluid>
                    <v-text-field
                        rounded
                        outlined
                        dense
                        :rules="textRules"
                        label="SKU Code"
                        v-model="value.skuCode"
                        required></v-text-field>
                    <v-autocomplete
                        rounded
                        outlined
                        dense
                        :rules="dropdownRules"
                        :items="brands"
                        item-text="displayName"
                        item-value="id"
                        label="Brand Name"
                        v-model="value.brandId"
                        required>
                        <template v-slot:no-data>
                            <v-card elevation="0">
                                <v-card-text class="text-center">
                                    <h4 class="mb-2">Brand name not found</h4>
                                    <v-btn
                                        depressed
                                        small
                                        rounded
                                        color="primary"
                                        @click="
                                            dialog = !dialog;
                                            dropdownId = brandDropdownId;
                                        ">
                                        <i class="fas fa-plus mr-2"></i>
                                        Add Brand
                                    </v-btn>
                                </v-card-text>
                            </v-card>
                        </template>
                    </v-autocomplete>
                    <v-autocomplete
                        rounded
                        outlined
                        dense
                        :rules="dropdownRules"
                        :items="categories"
                        item-text="displayName"
                        item-value="id"
                        label="Category"
                        v-model="value.lvl1Id"
                        required>
                        <template v-slot:no-data>
                            <v-card elevation="0">
                                <v-card-text class="text-center">
                                    <h4 class="mb-2">Category not found</h4>
                                    <v-btn
                                        depressed
                                        small
                                        rounded
                                        color="primary"
                                        @click="
                                            dialog = !dialog;
                                            dropdownId = categoryDropdownId;
                                        ">
                                        <i class="fas fa-plus mr-2"></i>
                                        Add Category
                                    </v-btn>
                                </v-card-text>
                            </v-card>
                        </template></v-autocomplete
                    >
                    <v-autocomplete
                        rounded
                        outlined
                        dense
                        :rules="dropdownRules"
                        :items="productTypes"
                        item-text="displayName"
                        item-value="id"
                        label="Type"
                        v-model="value.lvl2Id"
                        required>
                        <template v-slot:no-data>
                            <v-card elevation="0">
                                <v-card-text class="text-center">
                                    <h4 class="mb-2">Product type not found</h4>
                                    <v-btn
                                        depressed
                                        small
                                        rounded
                                        color="primary"
                                        @click="
                                            dialog = !dialog;
                                            dropdownId = productTypeDropdownId;
                                        ">
                                        <i class="fas fa-plus mr-2"></i>
                                        Add Type
                                    </v-btn>
                                </v-card-text>
                            </v-card>
                        </template></v-autocomplete
                    >
                    <v-autocomplete
                        rounded
                        outlined
                        dense
                        :items="[
                            { val: true, text: 'Yes' },
                            { val: false, text: 'No' },
                        ]"
                        item-text="text"
                        item-value="val"
                        label="Update Quantity Upon Order"
                        v-model="value.updateQuantity">
                    </v-autocomplete>
                    <v-autocomplete
                        rounded
                        outlined
                        dense
                        :items="images"
                        item-text="id"
                        label="Main Image"
                        v-model="value.pictureId">
                        <template v-slot:item="{ item }">
                            <v-container
                                class="py-0 my-0 d-flex align-center justify-space-around">
                                <div class="subtitle-1">{{ item.id }}</div>
                                <img
                                    class="image-preview"
                                    :src="getProductPictureUrlById(item.id)" />
                            </v-container>
                        </template>
                    </v-autocomplete>
                    <v-container
                        class="d-flex align-center justify-space-around">
                        <h3>Main Image Preview</h3>
                        <img
                            class="image-preview-lg"
                            :src="getProductPictureUrlById(value.pictureId)" />
                    </v-container>
                </v-container>
                <v-container fluid>
                    <v-text-field
                        rounded
                        outlined
                        dense
                        :rules="numberRules"
                        prefix="$"
                        label="Stock Price"
                        v-model.number="value.stockPrice"
                        required></v-text-field>
                    <v-text-field
                        rounded
                        outlined
                        dense
                        :rules="nonNegativeRules"
                        label="Quantity"
                        v-model.number="value.quantity"
                        required></v-text-field>
                    <v-text-field
                        rounded
                        outlined
                        dense
                        :rules="nonNegativeRules"
                        label="Quantity in US"
                        v-model.number="value.usQuantity"
                        required
                        @change="updateQuantity"></v-text-field>
                    <v-text-field
                        rounded
                        outlined
                        dense
                        :rules="nonNegativeRules"
                        label="Quantity in VN"
                        v-model.number="value.vnQuantity"
                        required
                        @change="updateQuantity"></v-text-field>

                    <v-autocomplete
                        rounded
                        outlined
                        dense
                        :items="currencyTypes"
                        :rules="dropdownRules"
                        item-text="displayName"
                        item-value="id"
                        label="Currency Type"
                        v-model.number="value.currencyId"
                        required></v-autocomplete>
                    <v-text-field
                        rounded
                        outlined
                        dense
                        :rules="numberRules"
                        :prefix="currencySymbol"
                        label="Unit Price"
                        v-model.number="value.unitPrice">
                    </v-text-field>
                    <date-picker
                        v-model="value.expDate"
                        label="Exp Date"></date-picker>
                </v-container>
            </v-container>
            <v-container fluid :class="ckeditorClass" class="white rounded-xl">
                <h3 class="mb-2">Product Description</h3>
                <ckeditor
                    :editor="editor"
                    :config="editorConfig"
                    v-model="value.description"></ckeditor>
            </v-container>
        </v-form>

        <dropdown-option-dialog
            v-model="dialog"
            :dropdown-id="dropdownId"></dropdown-option-dialog>
    </v-container>
</template>
<script>
import backendService from "@/services/backend-service";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DropdownOptionDialog from "./DropdownOptionDialog.vue";
import DatePicker from "@/components/DatePicker.vue";
import { CurrencyType } from "@/js/enum";
import generalMixin from "@/js/general-mixin";
export default {
    name: "ProductInfoForm",
    mixins: [generalMixin],
    components: {
        "dropdown-option-dialog": DropdownOptionDialog,
        "date-picker": DatePicker,
    },
    props: {
        product: {
            type: Object,
            default: () => {
                return {};
            },
        },
        images: {
            type: Array,
            default: () => {
                return [];
            },
        },
        pFormValid: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
    },
    data() {
        return {
            dialog: false,
            dropdownId: -1,
            editor: ClassicEditor,
            editorConfig: {
                toolbar: ["bold", "italic", "bulletedList", "numberedList"],
            },
            //formValid: false,
            dropdownRules: [
                (v) => {
                    if (v) return true;
                    return "Required";
                },
            ],
            numberRules: [
                (v) => {
                    if (v || v == 0) return true;
                    return "Required";
                },
                (v) => {
                    if (!isNaN(v)) return true;
                    return "Must be numeric";
                },
                (v) => {
                    if (v > 0) return true;
                    return "Must be greater than 0";
                },
            ],
            nonNegativeRules: [
                (v) => {
                    if (v || v == 0) return true;
                    return "Required";
                },
                (v) => {
                    if (!isNaN(v)) return true;
                    return "Must be numeric";
                },
            ],
            textRules: [
                (v) => {
                    if (v) return true;
                    return "Required";
                },
            ],

            titleRules: [
                (v) => {
                    if (v) return true;
                    return "Required";
                },
                (v) => {
                    if (v?.length >= 10) return true;
                    return "Length must be at least 10 characters";
                },
            ],
        };
    },
    computed: {
        brands() {
            if (this.$store.getters["dropdownToOptions"]["brand"])
                return this.$store.getters["dropdownToOptions"]["brand"]
                    .options;
            return [];
        },
        brandDropdownId() {
            if (this.$store.getters["dropdownToOptions"]["brand"])
                return this.$store.getters["dropdownToOptions"]["brand"].id;
            return -1;
        },
        ckeditorClass() {
            return {
                "ckeditor-error": !this.descriptionValid,
            };
        },
        currencySymbol() {
            let us = this.getIdByEnum(CurrencyType.UsDollar);
            let viet = this.getIdByEnum(CurrencyType.VietDong);
            if (this.product.currencyId == us) {
                return "$";
            } else if (this.product.currencyId == viet) {
                return "₫";
            }
            return "";
        },
        descriptionValid() {
            if (this.product.description?.length != 0) return true;
            return false;
        },

        formValid: {
            get() {
                return this.pFormValid;
            },
            set(val) {
                this.$emit("form-valid", val);
            },
        },
        value: {
            get() {
                return this.product;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
        categories() {
            if (this.$store.getters["dropdownToOptions"]["category"])
                return this.$store.getters["dropdownToOptions"]["category"]
                    .options;
            return [];
        },
        currencyTypes() {
            if (this.$store.getters["dropdownToOptions"]["currency"])
                return this.$store.getters["dropdownToOptions"]["currency"]
                    .options;
            return [];
        },
        categoryDropdownId() {
            if (this.$store.getters["dropdownToOptions"]["category"])
                return this.$store.getters["dropdownToOptions"]["category"].id;
            return -1;
        },
        productTypes() {
            if (this.$store.getters["dropdownToOptions"]["productType"])
                return this.$store.getters["dropdownToOptions"]["productType"]
                    .options;
            return [];
        },
        productTypeDropdownId() {
            if (this.$store.getters["dropdownToOptions"]["productType"])
                return this.$store.getters["dropdownToOptions"]["productType"]
                    .id;
            return -1;
        },
        stores() {
            return this.$store.getters["stores"];
        },
    },

    methods: {
        getProductPictureUrlById(imageId) {
            if (!imageId) return;
            return backendService.getProductPictureUrlById(imageId);
        },
        validate() {
            return this.$refs.form.validate();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        updateQuantity() {
            this.value.quantity = this.value.usQuantity + this.value.vnQuantity;
        },
    },
};
</script>
<style scoped>
.ckeditor-error {
    border: 1px solid lightcoral !important;
}
.image-preview {
    object-fit: contain;
    image-rendering: smooth;
    width: 60px;
    height: 60px;
}
.image-preview-lg {
    object-fit: contain;
    image-rendering: smooth;
    width: 100px;
    height: 100px;
}
</style>
